import React, { useRef, useState, useCallback, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const CropperRewardSponsorImage = ({ onCropComplete, onClose }) => {
  const imgRef = useRef(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [croppedBlob, setCroppedBlob] = useState(null);
  const [notification, setNotification] = useState(null);

  const cropOptions = {
    aspect: 93 / 40, // Rectangle aspect ratio
    minWidth: 465, // Minimum width
    minHeight: 200, // Minimum height
  };

  const [crop, setCrop] = useState({
    unit: "px",
    width: cropOptions.minWidth,
    height: cropOptions.minWidth / cropOptions.aspect,
    x: 0,
    y: 0,
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setImageUrl(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const generateCroppedBlob = useCallback(() => {
    if (!crop || !imgRef.current) return;

    const canvas = document.createElement("canvas");
    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;

    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      imgRef.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      canvas.width,
      canvas.height
    );

    canvas.toBlob((blob) => {
      setCroppedBlob(blob);
    }, "image/jpeg");
  }, [crop]);

  useEffect(() => {
    generateCroppedBlob();
  }, [generateCroppedBlob]);

  const handleImageLoad = () => {
    const image = imgRef.current;
    if (
      image.naturalWidth < cropOptions.minWidth ||
      image.naturalHeight < cropOptions.minHeight
    ) {
      setNotification(
        `Image is too small. Minimum size is ${cropOptions.minWidth}x${cropOptions.minHeight}.`
      );
      setImageUrl(null);
    } else {
      setNotification(null);
      generateCroppedBlob();
    }
  };

  const handleUpload = () => {
    if (croppedBlob) onCropComplete(croppedBlob);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-4 rounded-lg shadow-lg max-w-4xl w-full relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-700 hover:text-gray-900"
        >
          ✕
        </button>

        {!imageUrl && (
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="mb-4 w-full"
          />
        )}

        {notification && (
          <p className="text-red-500 mb-4 text-center">{notification}</p>
        )}

        {imageUrl && (
          <>
            <div className="flex justify-center overflow-auto max-h-96">
              <ReactCrop
                src={imageUrl}
                crop={crop}
                onChange={(newCrop) => setCrop(newCrop)}
                aspect={cropOptions.aspect}
              >
                <img
                  ref={imgRef}
                  alt="Crop Preview"
                  src={imageUrl}
                  onLoad={handleImageLoad}
                  style={{ maxWidth: "100%", maxHeight: "500px" }}
                />
              </ReactCrop>
            </div>
            <button
              onClick={handleUpload}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg mt-4"
            >
              Upload
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default CropperRewardSponsorImage;
