import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom';
import { FaPlus, FaTrashAlt, FaFilter, FaTimes } from "react-icons/fa";
import { fetchPlaypassGames } from '../../api/api';


const PlayPassList = () => {

    const [playPassData, setPlayPassData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filteredPlayPass, setFilteredPlayPass] = useState([])
    const [searchQuery, setSearchQuery] = useState(""); // State for search query

    console.log(`${process.env.REACT_APP_API_BASE}/playpass/games/`)

    useEffect(() => {
        const fetchPlayPassData = async () => {
            try {
                const playPassResponse = await fetchPlaypassGames()

                setPlayPassData(playPassResponse.games);
            } catch (error) {
                setError(error);

            }
        }
        fetchPlayPassData();
    }, [])

    useEffect(() => {

        console.log(playPassData)
        let updatedPlayPass = [...playPassData]

        // Apply search query filtering
        if (searchQuery) {
            updatedPlayPass = updatedPlayPass.filter((playPassData) =>
                playPassData.PlayPassTitle?.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        setFilteredPlayPass(updatedPlayPass)
    }, [searchQuery, playPassData])



    return (
        <div className='bg-gray-100 min-h-screen p-6'>
            <div className="flex justify-between items-center mb-6">
                {/* Heading */}
                <h1 className="text-3xl font-bold sm:text-4xl text-gray-800">
                    All Playpass Games
                </h1>

                {/* Create PlayPass Button */}
                <Link
                    to="/playpass/create"
                    className="bg-gradient-to-r from-purple-500 to-indigo-600 text-white p-3 rounded-lg shadow-lg hover:bg-indigo-700 flex items-center justify-center sm:w-auto w-12 h-12"
                >
                    <FaPlus size={20} className="sm:hidden" />
                    <span className="hidden sm:inline-block ml-2 text-base sm:text-lg">
                        Create Playpass Game
                    </span>
                </Link>

            </div>

            {/* Search and Sort Controls */}
            <div className="mb-6 flex justify-between items-center">

                {/* Search Box */}
                <input
                    type="text"
                    placeholder="Search PlayPasses"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full sm:w-1/2 md:w-1/3 px-4 py-2 border border-gray-300 rounded-lg shadow-md focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                />

            </div>

            {error ? (
                <div className='text-center'>
                    <h1>No Playpass games found</h1>
                </div>
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                    {filteredPlayPass ? (
                        filteredPlayPass.map((playpass) => (
                            <div
                                key={playpass.PlayPassId}
                                className="event-card p-4 bg-white shadow-lg rounded-lg hover:shadow-2xl transition duration-200 relative"
                            >
                                <h3 className="text-xl font-semibold text-gray-800 mb-2">
                                    {playpass.PlayPassTitle}
                                </h3>
                                <p className="text-gray-600 text-sm">
                                    {playpass.Description.length > 70
                                        ? `${playpass.Description.slice(0, 70)}...`
                                        : playpass.Description}
                                </p>
                                <p className='text-sm mt-4'>
                                    Created on : {new Date(playpass.CreatedAt).toLocaleTimeString(
                                        "en-IN", {
                                        day: "2-digit",
                                        month: "short",
                                        year: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true
                                    }
                                    )}
                                </p>

                                <Link
                                    to={`/playpass/details/${playpass.PlayPassId}`}
                                    className="text-indigo-500 hover:text-indigo-600 mt-4 inline-block font-medium"
                                >
                                    View Details
                                </Link>
                            </div>
                        ))
                    ) : (
                        <p className="text-gray-700 text-center col-span-3">
                            No events found matching the search query
                        </p>
                    )}
                </div>
            )}





        </div>
    )
}

export default PlayPassList
