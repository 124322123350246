import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchVenueSlots } from "../api/api";
import Loader from "../components/Loader";

const VenueSlotDetails = () => {
  const { venueId } = useParams(); // Get Venue ID
  const [slots, setSlots] = useState([]);
  const [filteredSlots, setFilteredSlots] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTime, setSearchTime] = useState(""); // Time input
  const [searchDate, setSearchDate] = useState(""); // Date input

  // Fetch Slots
  useEffect(() => {
    const getSlots = async () => {
      try {
        const data = await fetchVenueSlots(venueId);
        setSlots(data.VenueAllocation || []);
        setFilteredSlots(data.VenueAllocation || []);
      } catch (err) {
        console.error("Error fetching slots:", err);
        setError("Failed to fetch slot details.");
      } finally {
        setLoading(false);
      }
    };
    getSlots();
  }, [venueId]);

 // Function to subtract 5 hours and 30 minutes and normalize time
const subtract530FromTime = (timeStr) => {
  const [hours, minutes] = timeStr.split(":").map(Number);
  let date = new Date();

  // Set the input time into a date object
  date.setUTCHours(hours, minutes, 0, 0);

  // Subtract 5 hours and 30 minutes
  date.setUTCMinutes(date.getUTCMinutes() - 330);

  // Return adjusted time as "HH:MM"
  return date.toISOString().slice(11, 16);
};


  // Updated Search Logic
  const handleSearch = () => {
    const adjustedSearchTime = searchTime
      ? subtract530FromTime(searchTime)
      : "";

    const filtered = slots.filter((slot) => {
      const slotUTC = new Date(slot.Slot);
      const slotTime = slotUTC.toISOString().slice(11, 16); // Extract "HH:MM" from UTC time

      const slotDate = slotUTC.toISOString().slice(0, 10); // Extract "YYYY-MM-DD"

      const matchesTime = searchTime ? slotTime === adjustedSearchTime : true;
      const matchesDate = searchDate ? slotDate === searchDate : true;

      return matchesTime && matchesDate;
    });

    setFilteredSlots(filtered);
  };

  // Handle Reset
  const handleReset = () => {
    setSearchTime("");
    setSearchDate("");
    setFilteredSlots(slots);
  };

// Function to format date and time to "12 Dec 2024, 08:00 PM" in IST
const formatDateTime = (isoDate) => {
  const date = new Date(isoDate);

  // Convert to IST manually by adding 5 hours and 30 minutes
  date.setMinutes(date.getMinutes() + 330);

  return date.toLocaleString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true, // Use 12-hour format
    timeZone: "UTC", // Keep the timezone as UTC after manual adjustment
  });
};


  if (loading) return <Loader />;
  if (error) return <p className="text-red-500 text-center">{error}</p>;

  return (
    <div className="container mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">
        Slots for Venue
      </h2>

      {/* Search Filters */}
      <div className="flex flex-col sm:flex-row gap-4 mb-6">
        <input
          type="date"
          value={searchDate}
          onChange={(e) => setSearchDate(e.target.value)}
          className="w-full sm:w-1/3 p-2 border rounded"
          placeholder="Search by Date"
        />
        <input
          type="time"
          value={searchTime}
          onChange={(e) => setSearchTime(e.target.value)}
          className="w-full sm:w-1/3 p-2 border rounded"
          placeholder="Search by Time"
        />
        <button
          onClick={handleSearch}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
        >
          Search
        </button>
        <button
          onClick={handleReset}
          className="bg-gray-400 text-white px-4 py-2 rounded hover:bg-gray-500 transition"
        >
          Reset
        </button>
      </div>

      {/* Slots Table */}
      {filteredSlots.length === 0 ? (
        <p className="text-gray-500 text-center">No slots found.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="w-full border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="border px-4 py-2">Play Area</th>
                <th className="border px-4 py-2">Slot</th>
                <th className="border px-4 py-2">Price</th>
                <th className="border px-4 py-2">Price Credits</th>
                <th className="border px-4 py-2">Is Booked</th>
                <th className="border px-4 py-2">Recurrence Rule</th>
              </tr>
            </thead>
            <tbody>
              {filteredSlots.map((slot) => (
                <tr key={slot.VenueAllocationId} className="text-gray-700">
                  <td className="border px-4 py-2">{slot.PlayArea}</td>
                  <td className="border px-4 py-2">
                    {formatDateTime(slot.Slot)}
                  </td>
                  <td className="border px-4 py-2">{slot.Price}</td>
                  <td className="border px-4 py-2">{slot.PriceCredits}</td>
                  <td className="border px-4 py-2">
                    {slot.IsBooked ? "Yes" : "No"}
                  </td>
                  <td className="border px-4 py-2">
                    {slot.Schedule?.RecurrenceRule || "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default VenueSlotDetails;
