import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; // To get event ID from the URL
import { fetchPlaypassDetails, fetchUserDetails } from "../../api/api";
import { useNavigate } from "react-router-dom";
import {
    FaClipboardList,
    FaEdit,
    FaChevronDown,
    FaChevronUp,
} from "react-icons/fa";
import axios from "axios";

const PlaypassDetails = () => {

    const { id } = useParams();
    const [playpass, setPlaypass] = useState(null)
    const [organizerInfo, setOrganizerInfo] = useState(null)
    const [playerDetails, setPlayerDetails] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [inviteList, setInviteList] = useState([])
    const [teamInviteList, setTeamInviteList] = useState([])


    // Fetch event details when the component mounts
    useEffect(() => {
      
        const getPlaypassDetails = async () => {
            try {
                const playpassData = await fetchPlaypassDetails(id);
                console.log("viewing playpass data")
                console.log(playpassData.games[0])
                setPlaypass(playpassData.games[0]);

                const userId = playpassData.games[0].OrganizerId


                const OrganizerDetails = await fetchUserDetails(userId)

                setOrganizerInfo(OrganizerDetails)


                if (playpassData.games[0]?.InviteList?.length > 0) {
                   
                    // Create an array of promises for fetching invite list details
                    const promises = playpassData.games[0].InviteList.map((inviteId) =>
                        fetchUserDetails(inviteId)
                    );
                   
                    // Wait for all promises to resolve
                    const inviteResponses = await Promise.all(promises);
              

                    // Filter out any null responses
                    const validResponses = inviteResponses.filter((invite) => invite !== null);

                    // Set the invite list and player details state
                    setInviteList(validResponses);
                    setPlayerDetails(validResponses);
                }

            } catch (error) {
                setError("Failed to load event details");
                console.error("Error fetching event details:", error);
            } finally {
                setLoading(false);
            }
        };
        getPlaypassDetails();
    }, [id]);


    return (
        <div className="container mx-auto p-6 relative">

            {/* Playpass game Details */}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
                <h2 className="text-4xl font-bold text-gray-900 mb-4">
                    {playpass?.PlayPassTitle}
                </h2>

                <p className="text-lg text-gray-700 mb-4">
                    <strong>Description:</strong> {playpass?.Description}
                </p>
                <p className="text-lg text-gray-700 mb-4">
                    <strong>Organizer:</strong> {organizerInfo?.FirstName}{" "}{organizerInfo?.LastName}
                </p>
            </div>

            {/* Playpass game information Section */}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
                <h3 className="text-4xl font-bold text-gray-900 mb-4">
                    Playpass Game Information
                </h3>
                <div className="space-y-4">
                    <p className="text-lg text-gray-700">
                        <strong>Playpass Type:</strong> {playpass?.PlayPassType}
                    </p>
                    <p className="text-lg text-gray-700">
                        <strong>Playpass access:</strong>{" "}
                        {playpass?.PlayPassAccess || "Not Available"}
                    </p>
                    <p className="text-lg text-gray-700">
                        <strong>Skill Level:</strong> {playpass?.SkillLevel}
                    </p>
                    <p className="text-lg text-gray-700">
                        <strong>Team Size:</strong> {playpass?.TeamSize}
                    </p>
                    <p className="text-lg text-gray-700">
                        <strong>Total Teams:</strong> {playpass?.TotalTeams}
                    </p>
                    <p className="text-lg text-gray-700">
                        <strong>Registered Players:</strong> {playpass?.RegisteredPlayers}
                    </p>

                </div>
            </div>

            {/* Invite List */}

            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
                <h2 className="text-4xl font-bold text-gray-900 mb-4">
                    Invite List
                </h2>
                {playpass?.InviteList?.length > 0 ? (
                    <div className="text-lg text-gray-700 mb-4">
                        {playpass.InviteList.map((player, index) => (
                            <div key={index} className="mb-2">
                                <p className="font-semibold">Player {index + 1}: {inviteList[index]?.FirstName}{" "} {inviteList[index]?.LastName} </p>
                            </div>
                        ))}
                    </div>
                ) : ("No invite list found!")}


            </div>


            {/* Team invite list */}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
                <h2 className="text-4xl font-bold text-gray-900 mb-4">
                    Team Invite List
                </h2>
                {playpass?.TeamInviteList?.length > 0 ? (
                    <div className="text-lg text-gray-700 mb-4">
                        {playpass.TeamInviteList.map((player, index) => (
                            <div key={index} className="mb-2">
                                <p>Player: {player}</p>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className="text-gray-700">No team invites available.</p>
                )}
            </div>


            {/* Venue Details */}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
                <h2 className="text-4xl font-bold text-gray-900 mb-4">
                    Venue
                </h2>

                <p className="text-lg text-gray-700 mb-4">
                    <strong>Name:</strong> {playpass?.VenueName}
                </p>
                <p className="text-lg text-gray-700 mb-4">
                    <strong>Map Link: </strong>  <a className="text-blue-700" target="_blank" href={`${playpass?.VenueMapLink}`}> {playpass?.VenueMapLink} </a>
                </p>
                <p className="text-lg text-gray-700 mb-4">
                    <strong>Play Area: </strong> {playpass?.VenueAllocation?.PlayArea}
                </p>
                <p className="text-lg text-gray-700 mb-4">
                    <strong>Slot: </strong>{new Date(playpass?.VenueAllocation?.Slot).toLocaleDateString(
                        "en-US",
                        {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                        }
                    )}
                </p>
                <p className="text-lg text-gray-700 mb-4">
                    <strong>Price (INR): </strong> {playpass?.Price}
                </p>
                <p className="text-lg text-gray-700 mb-4">
                    <strong>Price (Credits): </strong> {playpass?.PriceCredits}
                </p>
                <p className="text-lg text-gray-700 mb-4">
                    <strong>Is Booked: </strong> {playpass?.VenueAllocation[0]?.IsBooked ? "Yes" : "No"}
                </p>
            </div>

            {/* Playpass game Schedule */}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
                <h2 className="text-4xl font-bold text-gray-900 mb-4">
                    Schedule
                </h2>

                <p className="text-lg text-gray-700 mb-4">
                    <strong>Start Date and Time:</strong> {new Date(playpass?.Schedule?.StartTimeStamp).toLocaleTimeString(
                        "en-US",
                        {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                        }
                    )}
                </p>
                <p className="text-lg text-gray-700 mb-4">
                    <strong>End Date and Time:</strong> {new Date(playpass?.Schedule?.EndTimeStamp).toLocaleTimeString(
                        "en-US",
                        {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                        }
                    )}
                </p>
                <p className="text-lg text-gray-700 mb-4">
                    <strong>Is Recurring:</strong> {playpass?.Schedule?.IsRecurring ? "Yes" : "No"}
                </p>

                {/* If it's recurring playpass game then show this */}
                {playpass?.Schedule?.IsRecurring && (<>
                    <p className="text-lg text-gray-700 mb-4">
                        <strong>Frequency:</strong> {playpass?.Schedule?.RecurrenceRule}
                    </p>
                    <p className="text-lg text-gray-700 mb-4">
                        <strong>Recurrence Interval:</strong> {playpass?.Schedule?.RecurrenceInterval}
                    </p>
                    <p className="text-lg text-gray-700 mb-4">
                        <strong>Recurrence End Date and Time:</strong> {new Date(playpass?.Schedule?.RecurrenceEndDate).toLocaleTimeString(
                            "en-us", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                        }
                        )}
                    </p>
                </>)}


                {playpass?.Schedule?.RecurrenceDaysOfWeek.length > 0 ? (
                    <div className="text-lg text-gray-700 mb-4 flex gap-x-2">
                        <strong>Recurrence Days of Week:</strong>
                        {playpass?.Schedule[0]?.RecurrenceDaysOfWeek.map((day, index) => (
                            <p key={index}>{day}</p>
                        ))}
                    </div>
                ) : ''}


            </div>

            {/* FAQs*/}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
                <h2 className="text-4xl font-bold text-gray-900 mb-4">
                    FAQs
                </h2>
                {playpass?.FAQ?.length > 0 ? (
                    <div className="text-lg text-gray-700">
                        {playpass.FAQ.map((faq, index) => (
                            <div key={index} className="mb-4">
                                <p className="font-bold ">Ques {index+1}: {faq.question}</p>
                                <p className="font-semibold">Ans: {faq.answer}</p>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className="text-gray-700">No FAQs available.</p>
                )}

            </div>

        </div>
    )
}

export default PlaypassDetails
