import React, { useState } from "react";
import { uploadEventImage, createSponsor, downloadFile } from "../api/api";
import { useNavigate } from "react-router-dom";
import CropperSponsorImage from "../components/CropperSponsorImage";
import ToastNotification from "../components/ToastNotification";
import imageCompression from "browser-image-compression";
import ClipLoader from "react-spinners/ClipLoader";

const CreateSponsor = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    sponsor_type: "default",
    SponsorImages: [], // Array to hold multiple images
  });
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [toast, setToast] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [usedCropOptions, setUsedCropOptions] = useState([]);

  // Access token from localStorage
  // const token = localStorage.getItem("auth")
  //   ? JSON.parse(localStorage.getItem("auth")).token
  //   : null;

  const handleAddCroppedImage = async (blob, tag) => {
    await handleImageCrop(blob, tag);
  };

  // Function to handle image cropping and upload
  const handleImageCrop = async (blob, tag) => {

    const maxSize = 1 * 1024 * 1024; // 1.5MB in bytes

    // If the image size exceeds the limit, compress it
    let imageBlob = blob;
    if (blob.size > maxSize) {
      try {
        const options = {
          maxSizeMB: 1, // Maximum size in MB
          useWebWorker: true, // Enable web worker for better performance
        };

        setToast({
          message: "Size > 1 MB, Compressing image...",
          type: "info",
        });

        imageBlob = await imageCompression(blob, options);
        console.log("compressed image size:", imageBlob.size / 1024, "KB")

        // Check if the compression was successful
        if (imageBlob.size > maxSize) {
          setToast({
            message: "Image compression failed to reduce size under 1.5MB!",
            type: "error",
          });
          return;
        }

        setToast({
          message: "Image compressed successfully!",
          type: "success",
        });
      } catch (error) {
        console.error("Error compressing image:", error);
        setToast({
          message: "Image compression failed!",
          type: "error",
        });
        return;
      }
    }


    const uploadFormData = new FormData();
    uploadFormData.append("data", imageBlob);
    uploadFormData.append("alt_text", tag);
    setImageLoading(true);

    try {
      const response = await uploadEventImage(uploadFormData);
      if (response && response.FileId) {
        setFormData((prevData) => ({
          ...prevData,
          SponsorImages: [
            ...prevData.SponsorImages,
            { ImageId: response.FileId, Tags: tag },
          ],
        }));

        // Mark this crop option as used
        setUsedCropOptions((prevOptions) => [...prevOptions, tag]);

        setToast({ message: "Image uploaded successfully!", type: "success" });
        setImageLoading(false);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      setToast({ message: "Image upload failed!", type: "error" });
    } finally {
      setShowCropper(false);
      setImageLoading(false);
    }
  };

  // Function to preview an image
  const handlePreviewImage = async (fileId) => {
    try {
      const imageUrl = await downloadFile(fileId); // downloadFile already returns a URL
      setPreviewImage(imageUrl);
    } catch (error) {
      console.error("Error downloading image:", error);
      setToast({ message: "Failed to load image preview.", type: "error" });
    }
  };

  // Function to remove an image
  const handleRemoveImage = (fileId, tag) => {
    setFormData((prevData) => ({
      ...prevData,
      SponsorImages: prevData.SponsorImages.filter(
        (image) => image.ImageId !== fileId
      ),
    }));

    // Remove the used tag from the usedCropOptions
    setUsedCropOptions((prevOptions) =>
      prevOptions.filter((option) => option !== tag)
    );

    setToast({ message: "Image removed successfully!", type: "success" });
  };

  // Check if all crop options are used
  const isAllCropOptionsUsed = () => {
    const allCropOptions = ["RECTANGLE", "SQUARE"];
    return allCropOptions.every((option) => usedCropOptions.includes(option));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setToast(null);

    // if (!token) {
    //   setToast({
    //     message: "Access token is missing/expired. Please log in.",
    //     type: "error",
    //   });
    //   setLoading(false);
    //   return;
    // }

    if (formData.SponsorImages.length === 0) {
      setToast({ message: "At least one image is required!", type: "error" });
      setLoading(false);
      return;
    }

    try {
      await createSponsor(formData);
      setToast({ message: "Sponsor created successfully!", type: "success" });
      navigate("/sponsors");
    } catch (err) {
      console.error("Error creating sponsor:", err);
      setToast({
        message: "Failed to create sponsor. Please try again.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md max-w-lg mx-auto">
      <h2 className="text-3xl font-bold mb-6 text-center">
        Create New Sponsor
      </h2>

      {toast && (
        <ToastNotification
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}

      <form onSubmit={handleSubmit}>
        {/* Sponsor Name */}
        <div className="mb-6">
          <label className="block text-lg font-semibold mb-2" htmlFor="name">
            Sponsor Name
          </label>
          <input
            type="text"
            name="name"
            id="name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          />
        </div>

        {/* Image Cropper */}
        <div className="mb-6">
          <button
            type="button"
            onClick={() => setShowCropper(true)}
            disabled={isAllCropOptionsUsed()}
            className={`bg-blue-500 text-white p-3 rounded-lg w-full transition ${isAllCropOptionsUsed()
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-blue-600"
              }`}
          >
            {imageLoading ? (<>
              <div className="w-30 gap-x-1 flex items-center">
                <ClipLoader color={"white"} loading={true} size={20} />
              </div>
            </>) : ('Add Image')}
          </button>
        </div>

        {showCropper && (
          <CropperSponsorImage
            onCropComplete={handleAddCroppedImage}
            onClose={() => setShowCropper(false)}
            usedCropOptions={usedCropOptions}
          />
        )}

        {/* Display Uploaded Images */}
        {formData.SponsorImages.length > 0 && (
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-4">Uploaded Images</h3>
            <ul className="space-y-2">
              {formData.SponsorImages.map((image) => (
                <li
                  key={image.ImageId}
                  className="flex items-center justify-between bg-gray-100 p-3 rounded-lg"
                >
                  <div>
                    <p className="font-medium">Tag: {image.Tags}</p>
                  </div>
                  <div className="flex space-x-2">
                    <button
                      type="button"
                      onClick={() => handlePreviewImage(image.ImageId)}
                      className="text-blue-500 hover:underline"
                    >
                      Preview
                    </button>
                    <button
                      type="button"
                      onClick={() =>
                        handleRemoveImage(image.ImageId, image.Tags)
                      }
                      className="text-red-500 hover:underline"
                    >
                      Remove
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Image Preview Overlay */}
        {previewImage && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-4 rounded-lg shadow-lg relative max-w-lg w-full">
              <button
                type="button"
                onClick={() => {
                  URL.revokeObjectURL(previewImage);
                  setPreviewImage(null);
                }}
                className="absolute top-2 right-2 text-gray-700 hover:text-gray-900 text-2xl"
              >
                ✕
              </button>
              <img
                src={previewImage}
                alt="Preview"
                className="max-w-full max-h-[60vh] object-contain mb-4"
              />
            </div>
          </div>
        )}

        {/* Submit Button */}
        <div className="mb-6 flex justify-center">
          <button
            type="submit"
            className="bg-[#2D1066] text-white p-3 rounded-lg w-full"
            disabled={loading}
          >
            {loading ? "Saving..." : "Save Sponsor"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateSponsor;
