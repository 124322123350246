import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  fetchEventMembers,
  fetchWaitlist,
  fetchUserById,
  fetchOrderById,
} from "../api/api";
import * as XLSX from "xlsx";

const RegistrationDetails = () => {
  const { eventId } = useParams();
  const [tab, setTab] = useState("members");
  const [eventMembers, setEventMembers] = useState([]);
  const [groupedMembers, setGroupedMembers] = useState({});
  const [waitlist, setWaitlist] = useState([]);
  const [userProfiles, setUserProfiles] = useState({});
  const [orderTimestamps, setOrderTimestamps] = useState({});
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [loadingWaitlist, setLoadingWaitlist] = useState(false);
  const [membersError, setMembersError] = useState("");
  const [waitlistError, setWaitlistError] = useState("");

  useEffect(() => {
    if (!eventId) {
      setMembersError("Event ID is missing.");
      return;
    }

    const fetchMembers = async () => {
      try {
        setLoadingMembers(true);

        // Fetch members for the specific eventId
        const members = await fetchEventMembers(eventId);

        // Group members by OrderId
        const grouped = members.reduce((acc, member) => {
          acc[member.OrderId] = acc[member.OrderId] || [];
          acc[member.OrderId].push(member);
          return acc;
        }, {});

        setGroupedMembers(grouped);
        setEventMembers(members);
        setMembersError(null); // Clear any previous errors
      } catch (error) {
        if (error.response?.status === 404) {
          setEventMembers([]); // Clear event members on 404
          setGroupedMembers({}); // Clear grouped members on 404
          setMembersError(null); // Do not show any error message for 404
        } else {
          setEventMembers([]); // Clear event members on other errors
          setGroupedMembers({}); // Clear grouped members on other errors
          setMembersError("Failed to fetch event members."); // Generic error message
        }
      } finally {
        setLoadingMembers(false);
      }
    };

    fetchMembers();
  }, [eventId]);

  useEffect(() => {
    const fetchOrderTimestamps = async () => {
      const timestamps = {};
      for (const member of eventMembers) {
        const orderId = member.OrderId;
        if (!timestamps[orderId]) {
          try {
            const orderData = await fetchOrderById(orderId);
            timestamps[orderId] = orderData?.CreatedAtTimeStamp || "N/A";
          } catch (error) {
            timestamps[orderId] = "Error";
          }
        }
      }
      setOrderTimestamps(timestamps);
    };

    if (eventMembers.length > 0) {
      fetchOrderTimestamps();
    }
  }, [eventMembers]);

  // Fetch User Profiles for Members
  useEffect(() => {
    const fetchProfiles = async () => {
      const profiles = {};
      for (const member of eventMembers) {
        if (!userProfiles[member.UserId]) {
          try {
            const profile = await fetchUserById(member.UserId);
            profiles[member.UserId] = profile;
          } catch (error) {
            if (error.response?.status === 404) {
              console.warn(`UserId not found: ${member.UserId}`);
              profiles[member.UserId] = null; // Mark as not found
            } else {
              console.error(`Failed to fetch profile for UserId: ${member.UserId}`, error);
            }
          }
        }
      }
      setUserProfiles((prev) => ({ ...prev, ...profiles }));
    };

    fetchProfiles();
  }, [eventMembers]);

  // Fetch Waitlist
  useEffect(() => {
    const fetchWaitlistData = async () => {
      try {
        setLoadingWaitlist(true);

        const waitlistData = await fetchWaitlist(eventId);

        if (waitlistData.message) {
          setWaitlist([]); // Set an empty array for waitlist
          setWaitlistError(waitlistData.message); // Display the message
        } else {
          setWaitlist(waitlistData); // Set the fetched waitlist data
          setWaitlistError(null); // Clear any previous errors
        }
      } catch (error) {
        setWaitlist([]); // Clear waitlist on error
        setWaitlistError(
          error.response?.status === 404
            ? "No waitlist found for this event."
            : "Failed to fetch waitlist."
        );
      } finally {
        setLoadingWaitlist(false);
      }
    };

    fetchWaitlistData();
  }, [eventId]);

  const handleExportMembers = () => {
    const data = [];
    Object.keys(groupedMembers).forEach((orderId) => {
      groupedMembers[orderId].forEach((member) => {
        const profile = userProfiles[member.UserId];
        const createdAt = orderTimestamps[member.OrderId]
          ? new Date(orderTimestamps[member.OrderId]).toLocaleString()
          : "N/A";
        if (profile) {
          data.push({
            OrderID: member.OrderId,
            CreatedAt: new Date(
              orderTimestamps[member.OrderId]
            ).toISOString().replace("T", " ").slice(0, 19),
            UserId: member.UserId,
            Name: `${profile.User.FirstName} ${profile.User.LastName}`,
            Mobile: `${profile.User.MobileNumber}`,
            Email: profile.User.EmailAddress,
            Gender: profile.User.Gender,
          });
        }
      });
    });

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `Event Members`);
    XLSX.writeFile(workbook, `EventMembers.xlsx`);
  };

  // Export Waitlist to Excel
  const handleExportWaitlist = () => {
    const data = waitlist.map((item) => {
      const user = userProfiles[item.UserId];
      return {
        Name: user ? `${user.FirstName} ${user.LastName}` : "N/A",
        Mobile: user ? `${user.mobile}` : "N/A",
        Email: user ? user.EmailAddress : "N/A",
        Gender: user ? user.Gender : "N/A",
        OrderAmount: item.OrderAmount,
        OrderStatus: item.OrderStatus,
        CreatedAt: new Date(item.CreatedAtTimeStamp).toLocaleString(),
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `Waitlist`);
    XLSX.writeFile(workbook, `EventWaitlist.xlsx`);
  };

  const renderTabContent = () => {
    if (tab === "members") {
      if (loadingMembers) {
        return <div>Loading event members...</div>;
      }

      if (membersError) {
        return <div className="text-red-500">{membersError}</div>;
      }

      if (!eventMembers || eventMembers.length === 0) {
        return <div>No event members available</div>;
      }

      return (
        <div>
          <button
            onClick={handleExportMembers}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 mb-4"
          >
            Export to Excel
          </button>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white rounded-lg shadow-lg overflow-hidden">
              <thead>
                <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                  {/* <th className="py-3 px-6 text-left">Order ID</th> */}
                  <th className="py-3 px-6 text-left">Created At</th>
                  <th className="py-3 px-6 text-left">User ID</th>
                  <th className="py-3 px-6 text-left">Name</th>
                  <th className="py-3 px-6 text-left">Mobile</th>
                  <th className="py-3 px-6 text-left">Email</th>
                  <th className="py-3 px-6 text-left">Gender</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light">
                {Object.keys(groupedMembers).map((orderId) => {
                  const orderMembers = groupedMembers[orderId];

                  return (
                    <React.Fragment key={orderId}>
                      {/* Order Row */}
                      <tr className="bg-gray-100 font-semibold">
                        <td className="py-3 px-6 text-left" colSpan="7">
                          Order ID: {orderId}
                        </td>
                      </tr>

                      {/* Member Rows */}
                      {orderMembers.map((member) => {
                        const profile = userProfiles[member.UserId];
                        return (
                          <tr
                            key={member.UserId}
                            className="border-b border-gray-200 hover:bg-gray-100"
                          >
                            
                            <td className="py-3 px-6 text-left">
                              {orderTimestamps[member.OrderId]
                                ? new Date(
                                    orderTimestamps[member.OrderId]
                                  ).toISOString().replace("T", " ").slice(0, 19)
                                : "Loading..."}
                            </td>
                            <td className="py-3 px-6 text-left whitespace-nowrap">
                              {member.UserId}
                            </td>
                            <td className="py-3 px-6 text-left font-medium">
                              {profile
                                ? `${profile.User.FirstName} ${profile.User.LastName}`
                                : "Loading..."}
                            </td>
                            <td className="py-3 px-6 text-left">
                              {profile
                                ? `${profile.User.MobileNumber}`
                                : "Loading..."}
                            </td>
                            <td className="py-3 px-6 text-left">
                              {profile ? profile.User.EmailAddress : "Loading..."}
                            </td>
                            <td className="py-3 px-6 text-left">
                              {profile ? profile.User.Gender : "Loading..."}
                            </td>
                          </tr>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }

    if (tab === "waitlist") {
      if (loadingWaitlist) {
        return <div>Loading waitlist...</div>;
      }

      if (waitlistError) {
        return <div>{waitlistError}</div>;
      }

      if (!waitlist || waitlist.length === 0) {
        return <div>No waitlist found for this event.</div>;
      }

      return (
        <div className="overflow-x-auto">
          <button
            onClick={handleExportWaitlist}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 mb-4"
          >
            Export to Excel
          </button>
          <table className="min-w-full bg-white rounded-lg shadow-lg overflow-hidden">
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left">Name</th>
                <th className="py-3 px-6 text-left">Mobile</th>
                <th className="py-3 px-6 text-left">Email</th>
                <th className="py-3 px-6 text-left">Gender</th>
                <th className="py-3 px-6 text-left">Order Amount</th>
                <th className="py-3 px-6 text-left">Order Status</th>
                <th className="py-3 px-6 text-left">Created At</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {waitlist.map((item, index) => {
                const user = userProfiles[item.UserId];
                return (
                  <tr
                    key={index}
                    className="border-b border-gray-200 hover:bg-gray-100"
                  >
                    <td className="py-3 px-6 text-left whitespace-nowrap font-medium">
                      {user ? `${user.FirstName} ${user.LastName}` : "N/A"}
                    </td>
                    <td className="py-3 px-6 text-left">
                      {user ? `${user.mobile}` : "N/A"}
                    </td>
                    <td className="py-3 px-6 text-left">
                      {user ? user.EmailAddress : "N/A"}
                    </td>
                    <td className="py-3 px-6 text-left">
                      {user ? user.Gender : "N/A"}
                    </td>
                    <td className="py-3 px-6 text-left">${item.OrderAmount}</td>
                    <td className="py-3 px-6 text-left text-[#ff0000] font-normal">
                      {item.OrderStatus}
                    </td>
                    <td className="py-3 px-6 text-left">
                      {new Date(item.CreatedAtTimeStamp).toLocaleString()}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }
  };

  return (
    <div className="p-4 bg-white shadow rounded">
      <h1 className="text-xl font-bold mb-4">Registration Details</h1>
      <div className="flex border-b border-gray-200">
        <button
          className={`py-2 px-4 ${
            tab === "members" ? "border-b-2 border-blue-500 text-blue-500" : ""
          }`}
          onClick={() => setTab("members")}
        >
          Event Members
        </button>
        <button
          className={`py-2 px-4 ${
            tab === "waitlist" ? "border-b-2 border-blue-500 text-blue-500" : ""
          }`}
          onClick={() => setTab("waitlist")}
        >
          Waitlist
        </button>
      </div>
      <div className="mt-4">{renderTabContent()}</div>
    </div>
  );
};

export default RegistrationDetails;
