import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../AuthContext'; // Assuming you have a custom auth context to manage login state

const PrivateRoute = ({ children }) => {
  const { auth } = useAuthContext(); // Get auth state from context

  // If no user is authenticated, redirect to the login page
  if (!auth) {
    return <Navigate to="/login" replace />;
  }

  // Otherwise, render the protected route
  return children;
};

export default PrivateRoute;
