import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../.././AuthContext";
import Select from 'react-select'
import {
    createPlaypass,
    fetchVenues,
    fetchSports,
    uploadEventImage,
    downloadFile,
    fetchVenueSlots,
    fetchAllUsers,
    getTeams
} from "../../api/api"; // Import API functions
import ToastNotification from "../.././components/ToastNotification";
import CropperEventImage from "../.././components/CropperEventImage";
import Loader from "../.././components/Loader";
import { FaSyncAlt, FaPlus } from "react-icons/fa";
import imageCompression from "browser-image-compression";
import ClipLoader from "react-spinners/ClipLoader";


const CreatePlaypass = () => {
    const { UserId } = useAuthContext();
    console.log("user id is:", UserId);
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        OrganizerId: UserId,
        PlayPassTitle: "",
        Description: "",
        PlayPassType: "", // Default value
        MatchType: '',      // Default value
        TotalRounds: '',
        PlayPassImages: [],
        EventImageFileId: [], // Array of strings for uploaded image IDs
        MatchFormat: '',        // Default value
        SkillLevel: "",  // Default value
        GameAccess: "",   // Default value
        InviteList: null,      // Default placeholder
        TeamInviteList: null,  // Default placeholder
        SportId: "",
        VenueAllocationId: [""],
        FAQ: [
            {
                question: "",
                answer: "",
            },
        ],
    });
    const [venues, setVenues] = useState([]);
    const [selectedVenue, setSelectedVenue] = useState("");
    const [venueSlots, setVenueSlots] = useState({});
    const { accessToken } = useAuthContext();
    const [toast, setToast] = useState(null);
    const [sports, setSports] = useState([]);
    const [showCropper, setShowCropper] = useState(false);
    const [usedCropOptions, setUsedCropOptions] = useState([]);

    const [previewImage, setPreviewImage] = useState(null);
    const [sponsorImages, setSponsorImages] = useState({});
    const [loading, setLoading] = useState(true);
    const [imageLoading, setImageLoading] = useState(false);

    //for slot selection
    const [selectedDay, setSelectedDay] = useState(""); // To store selected day
    const [availableDays, setAvailableDays] = useState([]); // To store unique days
    const [availableTimes, setAvailableTimes] = useState([]); // To store available slots for selected day
    const [selectedTime, setSelectedTime] = useState(""); // Track the selected time

    //for checking sport selection
    const [selectedSportId, setSelectedSportId] = useState('')

    //for invite input
    const [inviteInput, setInviteInput] = useState("")
    const [inviteFiltered, setInviteFiltered] = useState([])

    const [inviteOption, setInviteOption] = useState([])
    const [teamOption, setTeamOption] = useState([]);


    //fetching users
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const fetchAllUserData = await fetchAllUsers();

                // Map the API data to the format required by react-select
                const formattedOptions = fetchAllUserData.map((user) => ({
                    value: user.UserId, // User ID will be stored in 'value'
                    label: `${user.FirstName} ${user.LastName}`, // Full name will be stored in 'label'
                }));
                setInviteOption(formattedOptions)

            } catch (error) {
                console.error("Error fetching user data: ", error)
            }
        }
        setLoading(false)
        fetchData()
    }, [])

    //fetching teams
    useState(() => {
        setLoading(true)
        const fetchData = async () => {
            try {
                const fetchTeamData = await getTeams();
                // Map the API data to the format required by react-select
                const formattedOptions = fetchTeamData.map((team) => ({
                    value: team.TeamId, // User ID will be stored in 'value'
                    label: `${team.TeamName}`, // Full name will be stored in 'label'
                }));
                setTeamOption(formattedOptions)

            } catch (error) {
                console.error("Error fetching team data", error)
            }
        }
        setLoading(false)
        fetchData()
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const fetchedSports = await fetchSports();
                setSports(fetchedSports);
            } catch (error) {
                console.error("Error fetching details:", error);
            } finally {
                setLoading(false)
            }

        }
        fetchData();

    }, [])

    useState(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const fetchedVenues = await fetchVenues();
                setVenues(fetchedVenues)
            } catch (error) {
                console.error("Error fetching venues: ", error);
            } finally {
                setLoading(false);
            }
        }
        fetchData()
    }, [])



    const fetchImageUrl = async (fileId) => {
        try {
            const imageUrl = await downloadFile(fileId);
            return imageUrl;
        } catch (error) {
            console.error("Error fetching image URL:", error);
            return null;
        }
    };

    // Update handleImageCrop function to fetch image URL and set it for preview
    const handleImageCrop = async (blob, tag) => {

        const maxSize = 1 * 1024 * 1024; // 1.5MB in bytes
        // If the image size exceeds the limit, compress it
        let imageBlob = blob;
        if (blob.size > maxSize) {
            try {
                const options = {
                    maxSizeMB: 1, // Maximum size in MB
                    useWebWorker: true, // Enable web worker for better performance
                };
                setToast({
                    message: "Compressing image...",
                    type: "info",
                });
                imageBlob = await imageCompression(blob, options);
                // Check if the compression was successful
                if (imageBlob.size > maxSize) {
                    setToast({
                        message: "Image compression failed to reduce size under 1MB!",
                        type: "error",
                    });
                    return;
                }
                setToast({
                    message: "Image compressed successfully!",
                    type: "success",
                });
            } catch (error) {
                console.error("Error compressing image:", error);
                setToast({
                    message: "Image compression failed!",
                    type: "error",
                });
                return;
            }
        }


        const formData = new FormData();
        formData.append("data", imageBlob);
        formData.append("alt_text", tag);
        setImageLoading(true);

        try {
            const response = await uploadEventImage(formData);
            if (response && response.FileId) {
                const imageUrl = await fetchImageUrl(response.FileId);
                if (imageUrl) {
                    setFormData((prevData) => ({
                        ...prevData,
                        EventImageFileId: [...prevData.EventImageFileId, response.FileId],
                        PlayPassImages: [
                            ...prevData.PlayPassImages,
                            { FileId: response.FileId, Tags: [tag] },
                        ],
                    }));

                    setUsedCropOptions((prevOptions) =>
                        prevOptions.includes(tag) ? prevOptions : [...prevOptions, tag]
                    );

                    setToast({
                        message: "Image uploaded successfully!",
                        type: "success",
                    });
                }
            }
        } catch (error) {
            console.error("Error uploading image:", error);

            setToast({ message: "Image upload failed!", type: "error" });
        } finally {
            setShowCropper(false);
            setImageLoading(false);
        }
    };

    const handlePreviewImage = (fileId) => {
        const image = formData.PlayPassImages.find((image) => image.FileId === fileId);
        if (image && image.Url) {
            setPreviewImage(image.Url); // Use the image URL as the source
        }
    };
    // Remove Image
    const handleRemoveImage = (fileId) => {
        const imageToRemove = formData.PlayPassImages.find(
            (image) => image.FileId === fileId
        );

        if (imageToRemove) {
            setUsedCropOptions((prevOptions) =>
                prevOptions.filter((option) => option !== imageToRemove.Tags[0])
            );
        }

        setFormData((prevData) => ({
            ...prevData,
            EventImageFileId: prevData.EventImageFileId.filter((id) => id !== fileId),
            PlayPassImages: prevData.PlayPassImages.filter(
                (image) => image.FileId !== fileId
            ),
        }));
        setToast({ message: "Image removed successfully!", type: "success" });
    };

    // Add this function to check if all crop options are used
    const isAllCropOptionsUsed = () => {
        const allCropOptions = ["SPOTLIGHT", "TALL", "WIDE", "SQUARE"];
        return allCropOptions.every((option) => usedCropOptions.includes(option));
    };

    // Updated Add Image button with disabled state and toast notification
    const handleAddImageClick = () => {
        if (isAllCropOptionsUsed()) {
            setToast({
                message: "All images have been uploaded. No need to add more.",
                type: "info",
            });
        } else {
            setShowCropper(true);
        }
    };


    // Handle form field changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };





    //handle faq
    const handleFAQChange = (index, e) => {
        const { name, value } = e.target;
        const newFaqs = [...formData.FAQ];
        newFaqs[index] = { ...newFaqs[index], [name]: value };
        setFormData((prevData) => ({
            ...prevData,
            FAQ: newFaqs,
        }));
    };


    const addFAQ = () => {
        setFormData((prevData) => ({
            ...prevData,
            FAQ: [...prevData.FAQ, { question: "", answer: "" }],
        }));
    };


    //for handling  invite
    const handleInviteChange = (index, selectedOption) => {
 
        const UserId = selectedOption.value
        setFormData((prevFormData) => {
            const updatedInviteList = [...prevFormData.InviteList];
            updatedInviteList[index] = UserId // Update the specific team invite at the given index

            // Check if all values in the InviteList are empty, and set it to null if true
            const isAllEmpty = updatedInviteList.every((item) => item === "");

            return {
                ...prevFormData,
                InviteList: isAllEmpty ? null : updatedInviteList,
            };
        });
    };

    const addInvite = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            InviteList: prevFormData.InviteList ? [...prevFormData.InviteList, ""] : [""], // Add a new empty string
        }));
    };


    //for handling team invite
    const handleTeamInviteChange = (index, selectedOption) => {

        const teamId = selectedOption.value
        setFormData((prevFormData) => {
            const updatedTeamInviteList = [...prevFormData.TeamInviteList];
            updatedTeamInviteList[index] = teamId.trim(); // Update the 
            const isAllEmpty = updatedTeamInviteList.every((item) => item === "");

            return {
                ...prevFormData,
                TeamInviteList: isAllEmpty ? null : updatedTeamInviteList,
            };
        });
    };

    const addTeamInvite = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            TeamInviteList: prevFormData.TeamInviteList ? [...prevFormData.TeamInviteList, ""] : [""],
        }));
    };


    // For match format
    const matchFormatOptions = {
        "Pickleball": [
            { value: 1, label: 'Singles' },
            { value: 2, label: 'Doubles' },
        ],
        "Football": [
            { value: 5, label: '5V5' },
            { value: 6, label: '6V6' },
            { value: 7, label: '7V7' },
            { value: 8, label: '8V8' },
        ],
        "Padel": [
            { value: 2, label: 'Doubles' },
        ],
    };


    const selectedSportID = formData.SportId;

// Find the SportType
const selectedSport = sports.find(
    (sport) => sport.SportId === selectedSportID
);

let sportMatchFormats
if(selectedSport){
     sportMatchFormats = matchFormatOptions[selectedSport.SportType]
    console.log('sportMatchFormats: ', sportMatchFormats)
}


    // Handle Venue Change and Slot
    const handleVenueInputChange = async (e) => {
        const venueId = e.target.value; // Selected venue ID
        setSelectedVenue(venueId); // Set the selected venue
        setAvailableDays([]); // Reset available days
        setAvailableTimes([]); // Reset available times
        setSelectedDay(""); // Reset selected day
        setSelectedTime("");
        setFormData((prevFormData) => ({
            ...prevFormData,
            VenueAllocationId: [""], // Clear VenueAllocationId in formData
        }));

        try {
            // Fetch venue slots from the API
            const fetchedVenueSlots = await fetchVenueSlots(venueId);
            console.log("Fetched venue data:", fetchedVenueSlots);

            // Ensure VenueAllocation exists in the response
            if (fetchedVenueSlots && fetchedVenueSlots.VenueAllocation) {
                // Filter VenueAllocation based on the selectedSportId
                const filteredSlots = fetchedVenueSlots.VenueAllocation.filter(
                    (slot) => slot.SportId === selectedSportId // Compare with selectedSportId
                );

                if (filteredSlots.length > 0) {
                    setVenueSlots(filteredSlots); // Update venueSlots with filtered data

                    // Extract unique days
                    const uniqueDays = [
                        ...new Set(
                            filteredSlots.map((alloc) =>
                                new Date(alloc.Slot).toISOString().split("T")[0]
                            )
                        ),
                    ];
                    setAvailableDays(uniqueDays); // Update available days
                } else {
                    console.warn("No slots available for the selected sport.");
                    setVenueSlots([]);
                    setAvailableDays([]);
                }
            } else {
                console.warn("No VenueAllocation data found");
                setVenueSlots([]);
                setAvailableDays([]);
            }
        } catch (error) {
            console.error("Error fetching venue slot data:", error);
            setVenueSlots([]);
            setAvailableDays([]);
        }
    };

    // Function to handle day selection
    const handleDayChange = (e) => {
        const selectedDate = e.target.value; // Get the selected date
        setSelectedDay(selectedDate); // Update the selected day
        //reset selected time

        // Clear available times and VenueAllocationId
        setAvailableTimes([]);
        setFormData((prevFormData) => ({
            ...prevFormData,
            VenueAllocationId: [""], // Clear VenueAllocationId
        }));

        // Filter times for the selected day
        const timesForDay = venueSlots
            .filter((alloc) => new Date(alloc.Slot).toISOString().split("T")[0] === selectedDate)
            .map((alloc) => ({
                time: new Date(alloc.Slot).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
                id: alloc.VenueAllocationId,
                PlayArea: alloc.PlayArea
            }));


        setAvailableTimes(timesForDay); // Update available times
        setSelectedTime("");

    };


    // Function to handle time selection
    const handleTimeChange = (e) => {

        const selectedTimeSlotId = e.target.value; // Get the selected VenueAllocationId


        const timeSlot = availableTimes.find((slot) => slot.id === selectedTimeSlotId);

        if (timeSlot) {
            setSelectedTime(timeSlot.time); // Update with the selected time
        }

        // Update formData with the selected VenueAllocationId
        setFormData((prevFormData) => ({
            ...prevFormData,
            VenueAllocationId: selectedTimeSlotId, // Store the selected ID
        }));
    };



    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.PlayPassImages.length) {
            setToast({
                message: "Please upload at least one event image.",
                type: "error",
            });
            return;
        }

        try {
            await createPlaypass(formData);
            setToast({
                message: "Playpass game created successfully!",
                type: "success",
            });
            navigate("/playpass");
        } catch (error) {
            console.error("Error creating playpass game:", error);
            setToast({
                message: "Failed to create playpass game. Please try again.",
                type: "error",
            });
        }
    };

    console.log(formData)
    if (loading) {
        return <Loader />
    }

    return (
        <div className="p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-3xl font-bold mb-6 text-center">Create New Playpass Game</h2>

            {toast && (
                <ToastNotification
                    message={toast.message}
                    type={toast.type}
                    onClose={() => setToast(null)}
                />
            )}

            <form onSubmit={handleSubmit}>

                {/* Playpass game title */}
                <div className="mb-6">
                    <label
                        htmlFor="PlayPassTitle"
                        className="block text-lg font-semibold mb-2"
                    >
                        Playpass Game Title *
                    </label>
                    <input
                        type="text"
                        name="PlayPassTitle"
                        id="PlayPassTitle"
                        value={formData.PlayPassTitle}
                        onChange={handleInputChange}
                        className="w-full p-3 border border-gray-300 rounded-lg"
                        required
                    />
                </div>

                {/* Playpass Game Description */}
                <div className="mb-6">
                    <label
                        htmlFor="Description"
                        className="block text-lg font-semibold mb-2"
                    >
                        Playpass Game Description *
                    </label>
                    <textarea
                        name="Description"
                        id="Description"
                        value={formData.Description}
                        onChange={handleInputChange}
                        className="w-full p-3 border border-gray-300 rounded-lg"
                        required
                    />
                </div>

                {/* Playpass game type */}
                <div className="mb-6">
                    <label htmlFor="PlayPassType" className="block text-lg font-semibold mb-2">
                        Playpass Game Type*
                    </label>

                    <select
                        name="PlayPassType"
                        id="PlayPassType"
                        value={formData.PlayPassType}
                        onChange={handleInputChange}
                        className="w-full p-3 border border-gray-300 rounded-lg"
                    >

                        <option selected hidden disabled value="">Select Playpass Game Type</option>
                        <option value="Games">Games</option>


                    </select>
                </div>

                {/* Sport Dropdown */}
                <div className="mb-6">
                    <label htmlFor="SportId" className="block text-lg font-semibold mb-2">
                        Sport *
                    </label>
                    <select
                        name="SportId"
                        id="SportId"
                        value={formData.SportId}
                        onChange={(e) => {
                            const { name, value } = e.target;
                            // Store the selected sport ID in state
                            setSelectedSportId(value);
                            setFormData((prevData) => ({
                                ...prevData,
                                [name]: value,        // Update the selected SportId
                                MatchFormat: '',
                                // Reset MatchFormat to an empty string
                            }));
                        }}
                        className="w-full p-3 border border-gray-300 rounded-lg"
                        required
                    >
                        <option disabled selected hidden value="">Select a Sport</option>
                        {sports.map((sport) => (
                            <option key={sport.SportId} value={sport.SportId}>
                                {sport.SportType}
                            </option>
                        ))}
                    </select>
                </div>

                {
                    sportMatchFormats ? (
                        <div className="mb-6">
                            <label htmlFor="MatchFormat" className="block text-lg font-semibold mb-2">
                                Match Format *
                            </label>
                            <select
                                name="MatchFormat"
                                id="MatchFormat"
                                value={formData.MatchFormat}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        MatchFormat: parseInt(e.target.value, 10), // Parse value as an integer
                                    })
                                }
                                className="w-full p-3 border border-gray-300 rounded-lg"
                                required
                            >
                                <option disabled hidden value="">
                                    Select Match Format
                                </option>
                                {sportMatchFormats.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    ) : null
                }



                {/* Uploaded Images */}
                <div className="mb-6">
                    <h3 className="text-lg font-semibold mb-4">Uploaded Images</h3>
                    {formData.PlayPassImages.length > 0 ? (
                        <ul className="space-y-2">
                            {formData.PlayPassImages.map((image, index) => (
                                <li
                                    key={image.FileId}
                                    className="flex items-center justify-between bg-gray-100 p-3 rounded-lg"
                                >

                                    <div>
                                        <p className="font-medium">Image {index + 1}</p>
                                        <p className="text-sm text-gray-500">
                                            Tags: {image.Tags.join(", ")}
                                        </p>
                                    </div>
                                    <div className="flex space-x-2">
                                        {/* Preview Button */}
                                        <button
                                            type="button" // Prevents form submission
                                            onClick={() => handlePreviewImage(image.FileId)}
                                            className="text-blue-500 hover:underline"
                                        >
                                            Preview
                                        </button>
                                        {/* Remove Button */}
                                        <button
                                            type="button" // Prevents form submission
                                            onClick={() => handleRemoveImage(image.FileId)}
                                            className="text-red-500 hover:underline"
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>

                    ) : (
                        <p className="text-gray-500">No images uploaded yet.</p>
                    )}

                    {/* Image Preview Overlay */}
                    {previewImage && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                            <div className="bg-white p-4 rounded-lg shadow-lg relative max-w-lg w-full max-h-[80vh] overflow-auto">
                                <button
                                    type="button"
                                    onClick={() => {
                                        URL.revokeObjectURL(previewImage);
                                        setPreviewImage(null)
                                    }}
                                    className="absolute top-2 right-2 text-gray-700 hover:text-gray-900 text-2xl"
                                >
                                    ✕
                                </button>
                                <div className="flex items-center justify-center">
                                    <img
                                        src={previewImage}
                                        alt="Preview"
                                        className="max-w-full max-h-[60vh] object-contain"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {/* Add Image Button */}
                <div className="mb-6">
                    <button
                        type="button"
                        onClick={handleAddImageClick}
                        disabled={isAllCropOptionsUsed()}
                        className={`bg-blue-500 text-white p-2 rounded-lg transition duration-200 ${isAllCropOptionsUsed()
                            ? "opacity-50 cursor-not-allowed"
                            : "hover:bg-blue-600"
                            }`}
                    >
                        {imageLoading ? (<>
                            <div className="w-30 gap-x-1 flex items-center">
                                <p>Uploading</p> <ClipLoader color={"white"} loading={true} size={20} />
                            </div>
                        </>) : ('Add Image')}
                    </button>
                </div>

                {/* Cropping Tool Overlay */}
                {showCropper && (
                    <CropperEventImage
                        onCropComplete={handleImageCrop}
                        onClose={() => setShowCropper(false)}
                        usedCropOptions={usedCropOptions}
                    />
                )}

                {/* Match Type Dropdown */}
                <div className="mb-6">
                    <label htmlFor="MatchType" className="block text-lg font-semibold mb-2">
                        Match Type *
                    </label>
                    <select
                        name="MatchType"
                        id="MatchType"
                        value={formData.MatchType}
                        onChange={handleInputChange}
                        className="w-full p-3 border border-gray-300 rounded-lg"
                        required
                    >
                        <option disabled selected hidden value="">Select Match Type</option>
                        <option value="Men">Men's</option>
                        <option value="Women">Women's</option>
                        <option value="Mixed">Mixed / Co-ed</option>
                    </select>

                </div>



                {/* Playpass Game Description */}
                <div className="mb-6">
                    <label
                        htmlFor="TotalRounds"
                        className="block text-lg font-semibold mb-2"
                    >
                        Total Rounds *
                    </label>
                    <input
                        type="text"
                        name="TotalRounds"
                        id="TotalRounds"
                        value={formData.TotalRounds}
                        onChange={handleInputChange}
                        className="w-full p-3 border border-gray-300 rounded-lg"
                        required
                    />
                </div>



                {/* Skill Level */}
                <div className="mb-6">
                    <label
                        htmlFor="SkillLevel"
                        className="block text-lg font-semibold mb-2"
                    >
                        Skill Level
                    </label>
                    <select
                        name="SkillLevel"
                        id="SkillLevel"
                        value={formData.SkillLevel}
                        onChange={handleInputChange}
                        className="w-full p-3 border border-gray-300 rounded-lg"
                    >
                        <option selected hidden disabled value="">Select Skill level</option>
                        <option value="Novice">Novice</option>
                        <option value="Beginner">Beginner</option>
                        <option value="Intermediate">Intermediate</option>
                        <option value="Expert">Advanced/ Expert</option>
                    </select>
                </div>







                {/* Venue Dropdown */}
                <div className="mb-6">
                    <label htmlFor="SportId" className="block text-lg font-semibold mb-2">
                        Select Venue *
                    </label>
                    <select
                        name="venueId"
                        id="venueId"
                        value={selectedVenue}
                        onChange={handleVenueInputChange}
                        className="w-full p-3 border border-gray-300 rounded-lg"
                        required
                    >
                        <option disabled selected hidden value="">Select a Venue</option>
                        {venues.map((venue) => (
                            <option key={venue.VenueId} value={venue.VenueId}>
                                {venue.VenueName}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="-mt-2">
                    {selectedSportId.length > 0 ? ('') : (<p className="text-red-500 text-base mb-4">Select a sport first!</p>)}
                </div>

                {/* Slot selection Dropdown */}
                <div className="mb-6 flex gap-x-4">
                    {/* Day */}
                    <div className="w-[95%]">
                        <label htmlFor="day" className="block text-lg font-semibold mb-2">
                            Available Days*
                        </label>
                        <select
                            name="day"
                            id="day"
                            value={selectedDay}
                            onChange={handleDayChange}
                            className="w-full p-3 border border-gray-300 rounded-lg"
                            required
                        >
                            <option disabled selected hidden value="">Select Day</option>
                            {availableDays.map((day) => (
                                <option key={day} value={day}>
                                    {new Date(day).toLocaleDateString("en-US", {
                                        day: "2-digit",
                                        month: "long",
                                        year: "numeric"
                                    })} {/* Format day */}
                                </option>
                            ))}
                        </select>

                    </div>

                    {/* Time */}

                    <div className="w-[95%]">
                        <label htmlFor="time" className="block text-lg font-semibold mb-2">
                            Select Slot*
                        </label>
                        <select
                            name="time"
                            id="time"
                            value={selectedTime}
                            onChange={handleTimeChange}
                            className="w-full p-3 border border-gray-300 rounded-lg"
                            required
                        >
                            <option disabled selected hidden value="">Select Time</option>
                            {availableTimes.map((slot) => (

                                <option key={slot.id} value={slot.id}>
                                    {slot.time} {"  "} {`\t(Play Area: ${slot.PlayArea})`}

                                </option>

                            ))}
                        </select>
                    </div>
                </div>

                <div >
                    {availableDays.length > 0 ? ('') : (<p className="mb-4 text-base text-red-500">No slots available!</p>)}
                </div>




                {/* FAQ Section */}
                <div className="mb-6">
                    <label className="block text-lg font-semibold mb-2">FAQs</label>
                    {formData.FAQ.map((faq, index) => (
                        <div key={index} className="mb-4">
                            <input
                                type="text"
                                name="question"
                                placeholder="Question"
                                value={faq.question}
                                onChange={(e) => handleFAQChange(index, e)}
                                className="w-full p-3 border border-gray-300 rounded-lg mb-2"
                            />
                            <textarea
                                name="answer"
                                placeholder="Answer"
                                value={faq.answer}
                                onChange={(e) => handleFAQChange(index, e)}
                                className="w-full p-3 border border-gray-300 rounded-lg"
                            />
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={addFAQ}
                        className="bg-green-500 text-white p-2 rounded-lg"
                    >
                        Add FAQ
                    </button>
                </div>


                {/* Match Type Dropdown */}
                <div className="mb-6">
                    <label htmlFor="GameAccess" className="block text-lg font-semibold mb-2">
                        Game Access *
                    </label>
                    <select
                        name="GameAccess"
                        id="GameAccess"
                        value={formData.GameAccess}
                        onChange={handleInputChange}
                        className="w-full p-3 border border-gray-300 rounded-lg"
                        required
                    >
                        <option disabled selected hidden value="">Select Game Access</option>
                        <option value="Invite">Invite</option>
                        <option value="Open">Open</option>

                    </select>

                </div>


                {formData.GameAccess === "Invite" ? (

                    <>
                        {/* Add invite Section */}
                        <div className="mb-6">

                            <label className="block text-lg font-semibold mb-2">Invite People</label>
                            {formData.InviteList?.map((InviteList, index) => (
                                <div key={index} className="mb-4">
                                    <Select
                                        options={inviteOption}
                                        value={inviteOption.UserId}
                                        onChange={(selectedOption) => handleInviteChange(index, selectedOption)}
                                        placeholder="Search and select a user..."
                                        isSearchable // Enable search
                                    />
                                </div>
                            ))}




                            <button
                                type="button"
                                onClick={addInvite}
                                className="bg-green-500 text-white p-2 rounded-lg"
                            >
                                Add +
                            </button>
                        </div>
                        {/* Team invite Section */}
                        <div className="mb-6">
                            <label className="block text-lg font-semibold mb-2">Invite Teams</label>
                            {formData.TeamInviteList?.map((teamInvite, index) => (
                                <div key={index} className="mb-4">
                                    <Select
                                        options={teamOption}
                                        value={teamOption.TeamId}
                                        onChange={(selectedOption) => handleTeamInviteChange(index, selectedOption)}
                                        placeholder="Search and select a team..."
                                        isSearchable // Enable search
                                    />

                                </div>
                            ))}
                            <button
                                type="button"
                                onClick={addTeamInvite}
                                className="bg-green-500 text-white p-2 rounded-lg"
                            >
                                Add +
                            </button>
                        </div>

                    </>

                ) : ('')}



                {/* Submit Button */}
                <button
                    type="submit"
                    className="bg-blue-500 text-white p-3 rounded-lg mt-4 w-full"
                >
                    Create Playpass Game
                </button>


            </form>

        </div>
    )
}

export default CreatePlaypass
