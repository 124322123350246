import React from 'react';

const EventDetailsModal = ({ event, onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-8 rounded-lg shadow-lg w-96">
      <button onClick={onClose} className="text-gray-500 hover:text-gray-700 absolute top-4 right-4">X</button>
      <h2 className="text-2xl font-bold mb-4">{event.EventTitle}</h2>
      <p><strong>Description:</strong> {event.EventDescription}</p>
      <p><strong>Type:</strong> {event.EventType}</p>
      <p><strong>Price:</strong> {event.PriceCurrency} {event.Price}</p>
      <p><strong>Venue:</strong> {event.Venue.VenueName}, {event.Venue.VenueAddress.city}</p>
      <p><strong>Start Date:</strong> {new Date(event.EventSchedule.StartDate).toLocaleDateString()}</p>
      <p><strong>End Date:</strong> {new Date(event.EventSchedule.EndDate).toLocaleDateString()}</p>
      <button onClick={onClose} className="btn bg-blue-500 text-white p-2 mt-4 rounded">Close</button>
    </div>
  </div>
);

export default EventDetailsModal;