import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../AuthContext";
import { loginOTP, verifyOTP, fetchUserDetailsByPhone } from "../api/api"; // Import the new function
import ToastNotification from "../components/ToastNotification";

const LoginPage = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [toast, setToast] = useState(null); // For showing toast notifications
  const navigate = useNavigate();
  const { login, setAccessToken } = useAuthContext();

  // Handle Mobile Number Submit - Send OTP if user is an admin
  const handleMobileNumberSubmit = async () => {
    try {
      console.log("Checking user role...");

      // Fetch user details by phone number
      const userResponse = await fetchUserDetailsByPhone(mobileNumber);

      // Check if user exists and role is 'Admin'
      const user = userResponse?.users?.[0];
      if (!user) {
        setToast({ message: "User not found!", type: "error" });
        throw new Error("User not found.");
      }

      if (user.RoleType !== "Admin") {
        setToast({ message: "Access denied. You are not an admin.", type: "error" });
        return;
      }

      // Send OTP if role is admin
      console.log("Sending OTP...");
      await loginOTP({ mobile_number: mobileNumber, country_code: countryCode });

      console.log("OTP Sent!");
      setIsOtpSent(true);
      setErrorMessage("");
      setToast({ message: "OTP sent successfully!", type: "success" });
    } catch (error) {
      console.error("Error sending OTP:", error);
      setErrorMessage("Failed to send OTP. Please try again.");
    }
  };

  // Handle OTP Submit - Verify OTP and login
  const handleOtpSubmit = async () => {
    try {
      console.log("Verifying OTP...");

      const response = await verifyOTP({ mobile_number: mobileNumber, otp });

      console.log("OTP Verified!");
      const { AccessToken, UserId } = response;
 
      setAccessToken(AccessToken);
      // setUserId(UserId)

      login({ token: AccessToken, UserId}, 24);

      navigate("/");
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setErrorMessage("Invalid OTP. Please try again.");
    }
  };

  return (
    <div className="min-h-screen flex justify-center items-center bg-gray-100">
      <div className="bg-white rounded-lg shadow-xl p-8 w-full sm:w-96 md:w-96">
        <h2 className="text-3xl font-semibold text-center text-[#2D1066] mb-6">Login</h2>

        {toast && (
          <ToastNotification message={toast.message} type={toast.type} onClose={() => setToast(null)} />
        )}

        <div className="space-y-4">
          {!isOtpSent ? (
            <div className="space-y-4">
              <div className="flex flex-col">
                <label htmlFor="mobileNumber" className="text-lg font-semibold text-gray-700">
                  Mobile Number
                </label>
                <div className="flex items-center border-b-2 mt-2">
                  <input
                    type="text"
                    id="mobileNumber"
                    name="mobileNumber"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    className="flex-1 px-2 py-2 outline-none border-transparent focus:ring-0"
                    placeholder="Enter mobile number"
                  />
                </div>
              </div>

              <button
                onClick={handleMobileNumberSubmit}
                className="w-full bg-[#2D1066] text-white py-2 rounded-md hover:bg-[#6CB52D] transition"
              >
                Send OTP
              </button>
            </div>
          ) : (
            <div className="space-y-4">
              <div className="flex flex-col">
                <label htmlFor="otp" className="text-lg font-semibold text-gray-700">
                  Enter OTP
                </label>
                <div className="flex items-center border-b-2 mt-2">
                  <input
                    type="text"
                    id="otp"
                    name="otp"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    className="flex-1 px-2 py-2 outline-none border-transparent focus:ring-0"
                    placeholder="Enter OTP"
                  />
                </div>
              </div>

              <button
                onClick={handleOtpSubmit}
                className="w-full bg-[#2D1066] text-white py-2 rounded-md hover:bg-[#6CB52D] transition"
              >
                Verify OTP
              </button>
            </div>
          )}
        </div>

        {errorMessage && (
          <p className="mt-4 text-center text-red-500 font-semibold">{errorMessage}</p>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
