import React, { useState } from "react";
import { createRewardSponsor, uploadEventImage } from "../../api/api";
import CropperSponsorImage from "../../components/CropperRewardSponsorImage";


const CreateRewardSponsorForm = () => {
  const [formData, setFormData] = useState({
    RewardSponsorType: "",
    RewardSponsorName: "",
    RewardSponsorImageFileId: "",
    IsActive: true,
  });

  const [showCropper, setShowCropper] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleCropComplete = async (croppedBlob) => {
    try {
      setLoading(true);
      setError(null);

      const formData = new FormData();
      formData.append("file", croppedBlob);

      const response = await uploadEventImage(formData); // Upload image
      setFormData((prev) => ({
        ...prev,
        RewardSponsorImageFileId: response.fileId, // Store the uploaded file ID
      }));
    } catch (error) {
      console.error("Error uploading image:", error);
      setError("Failed to upload image. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      await createRewardSponsor(formData);
      setSuccessMessage("Reward sponsor created successfully!");
      setFormData({
        RewardSponsorType: "",
        RewardSponsorName: "",
        RewardSponsorImageFileId: "",
        IsActive: true,
      });
    } catch (error) {
      console.error("Error creating reward sponsor:", error);
      setError("Failed to create reward sponsor. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
        Create Reward Sponsor
      </h2>

      {error && <p className="text-red-500 text-center">{error}</p>}
      {successMessage && (
        <p className="text-green-500 text-center">{successMessage}</p>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Reward Sponsor Type */}
        <div>
          <label htmlFor="RewardSponsorType" className="block text-lg font-semibold mb-2">
            Reward Sponsor Type
          </label>
          <input
            type="text"
            name="RewardSponsorType"
            id="RewardSponsorType"
            value={formData.RewardSponsorType}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            placeholder="Enter the type (e.g., Corporate, Individual)"
            required
          />
        </div>

        {/* Reward Sponsor Name */}
        <div>
          <label htmlFor="RewardSponsorName" className="block text-lg font-semibold mb-2">
            Reward Sponsor Name
          </label>
          <input
            type="text"
            name="RewardSponsorName"
            id="RewardSponsorName"
            value={formData.RewardSponsorName}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            placeholder="Enter sponsor name"
            required
          />
        </div>

        {/* Sponsor Image */}
        <div>
          <label className="block text-lg font-semibold mb-2">Sponsor Image</label>
          <button
            type="button"
            onClick={() => setShowCropper(true)}
            className=" text-blue-500 font-medium px-4 py-2 rounded hover:bg-blue-100 border-[2px] border-blue-500"
          >
            Upload Image
          </button>
          {formData.RewardSponsorImageFileId && (
            <p className="text-green-500 mt-2">Image uploaded successfully!</p>
          )}
        </div>

        {/* Is Active Checkbox */}
        <div>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              name="IsActive"
              checked={formData.IsActive}
              onChange={handleInputChange}
              className="w-5 h-5 text-blue-500 border-gray-300 rounded focus:ring-blue-500"
            />
            <span className="text-lg font-semibold">Is Active</span>
          </label>
        </div>

        {/* Submit Button */}
        <div>
          <button
            type="submit"
            className={`w-full px-4 py-3 text-white rounded ${
              loading ? "bg-gray-500" : "bg-blue-500 hover:bg-blue-600"
            }`}
            disabled={loading}
          >
            {loading ? "Submitting..." : "Create Reward Sponsor"}
          </button>
        </div>
      </form>

      {/* Cropper Modal */}
      {showCropper && (
        <CropperSponsorImage
          onCropComplete={handleCropComplete}
          onClose={() => setShowCropper(false)}
        />
      )}
    </div>
  );
};

export default CreateRewardSponsorForm;
