import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchCoachingEvents, deleteCoachingEvent } from "../api/api";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import Loader from "../components/Loader";

const CoachingList = () => {
  const [coachingEvents, setCoachingEvents] = useState([]);
  const [filteredCoachingEvents, setFilteredCoachingEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const loadCoachingEvents = async () => {
      try {
        const data = await fetchCoachingEvents("asc", 100, 0);
        setCoachingEvents(data.events);
        setFilteredCoachingEvents(data.events);
      } catch (error) {
        console.error("Error fetching coaching events:", error);
        setCoachingEvents([]);
        setFilteredCoachingEvents([]);
        setError(null); // Reset error to avoid displaying an error message
      } finally {
        setLoading(false);
      }
    };

    loadCoachingEvents();
  }, []);

  // Handle event deletion
  const handleDelete = async (CoachOfferingId) => {
    if (window.confirm("Are you sure you want to delete this coaching event?")) {
      try {
        const token = localStorage.getItem("auth")
          ? JSON.parse(localStorage.getItem("auth")).token
          : null;

        if (!token) {
          alert("Access token is missing. Please log in.");
          return;
        }

        await deleteCoachingEvent(CoachOfferingId, token);
        setCoachingEvents((prev) => prev.filter((event) => event.CoachOfferingId !== CoachOfferingId));
        setFilteredCoachingEvents((prev) => prev.filter((event) => event.CoachOfferingId !== CoachOfferingId));
        alert("Coaching event deleted successfully!");
      } catch (error) {
        console.error("Error deleting coaching event:", error);
        alert("Failed to delete coaching event.");
      }
    }
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = coachingEvents.filter((event) =>
      event.EventTitle?.toLowerCase().includes(query)
    );

    setFilteredCoachingEvents(filtered);
  };

  if (loading) return <Loader />;

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Coaching Events</h1>
        <Link
          to="/coaching/create"
          className="bg-gradient-to-r from-purple-500 to-indigo-600 text-white p-3 rounded-lg shadow-lg hover:bg-indigo-700 flex items-center justify-center sm:w-auto w-12 h-12"
        >
          <FaPlus size={20} className="sm:hidden" />
          <span className="hidden sm:inline-block ml-2 text-base sm:text-lg">
            Create Coaching
          </span>
        </Link>
      </div>

      {/* Search Input */}
      <div className="mb-6">
        <input
          type="text"
          placeholder="Search coaching events by name"
          value={searchQuery}
          onChange={handleSearchChange}
          className="w-full sm:w-1/2 px-4 py-2 border border-gray-300 rounded-lg shadow-md focus:ring-2 focus:ring-indigo-500 focus:outline-none"
        />
      </div>

      {/* Coaching Events Grid */}
      {filteredCoachingEvents.length === 0 ? (
        <p className="text-gray-700 text-center text-lg">
          No coaching events found. Please create a new coaching event.
        </p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {filteredCoachingEvents.map((event) => (
            <div key={event.CoachOfferingId} className="bg-white p-4 rounded shadow">
              <h2 className="text-xl font-semibold">{event.CoachingTitle}</h2>
              <p className="text-gray-700 mb-2">
                {event.CoachingDescription?.length > 70
                  ? `${event.CoachingDescription.slice(0, 70)}...`
                  : event.CoachingDescription}
              </p>
              <div className="flex justify-between items-center">
                <Link
                  to={`/coaching/details/${event.CoachOfferingId}`}
                  className="text-blue-500 hover:underline"
                >
                  View Details
                </Link>
                <button
                  onClick={() => handleDelete(event.CoachOfferingId)}
                  className="text-red-500 hover:text-red-700"
                  title="Delete Event"
                >
                  <FaTrashAlt />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CoachingList;
