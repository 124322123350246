// src/pages/TeamsList.js
import React, { useState, useEffect } from 'react';
import { fetchTeams, fetchTeamMembers } from '../api/api'; // Assume these functions are already defined in api.js

const TeamsList = () => {
  const [teams, setTeams] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch teams when the component mounts
  useEffect(() => {
    const loadTeams = async () => {
      try {
        const teamsData = await fetchTeams(); // Fetch all teams
        setTeams(teamsData);
      } catch (error) {
        console.error('Error fetching teams:', error);
      } finally {
        setLoading(false);
      }
    };

    loadTeams();
  }, []);

  // Fetch team members when a team is selected
  const handleTeamClick = async (teamId) => {
    setSelectedTeamId(teamId);
    try {
      const members = await fetchTeamMembers(teamId); // Fetch team members
      setTeamMembers(members);
    } catch (error) {
      console.error('Error fetching team members:', error);
    }
  };

  return (
    <div className="teams-page p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-semibold mb-6">Teams</h2>
      
      {loading ? (
        <p>Loading teams...</p>
      ) : (
        <div>
          <h3 className="text-2xl font-medium mb-4">All Teams</h3>
          <ul>
            {teams.map((team) => (
              <li
                key={team.TeamId}
                onClick={() => handleTeamClick(team.TeamId)}
                className="cursor-pointer text-blue-500 hover:underline"
              >
                {team.TeamName}
              </li>
            ))}
          </ul>
          
          {selectedTeamId && (
            <div>
              <h3 className="text-2xl font-medium mt-6 mb-4">Team Members</h3>
              <ul>
                {teamMembers.map((member) => (
                  <li key={member.TeamMemberId}>
                    <p>{member.Name}</p>
                    <p>{member.Role}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TeamsList;
