import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchRegions, createVenue } from "../api/api"; // Import the API functions

const CreateVenue = () => {
  const navigate = useNavigate();

  // State for venue form data
  const [formData, setFormData] = useState({
    VenueName: "",
    VenueAddress: {
      country: "",
      state: "",
      city: "",
      pincode: "",
      landmark: "",
    },
    VenueRegionId: "", // This will be populated by the selected region
    MapLink: "",
    VenueDescription: "",
    Latitude: 0,
    Longitude: 0,
  });

  const [regions, setRegions] = useState([]); // To store the fetched regions
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch regions when the component mounts
  useEffect(() => {
    const getRegions = async () => {
      try {
        const regionsData = await fetchRegions();
        setRegions(regionsData); // Store the fetched regions in the state
      } catch (error) {
        setError("Failed to fetch regions");
        console.error("Error fetching regions:", error);
      }
    };

    getRegions();
  }, []);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const [field, subfield] = name.split("."); // Handle nested fields like `venue_address.country`

    if (subfield) {
      setFormData((prevData) => ({
        ...prevData,
        [field]: {
          ...prevData[field],
          [subfield]: value,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // Handle region selection change
  const handleRegionChange = (e) => {
    const selectedRegionId = e.target.value; // Get the selected region ID
    setFormData((prevData) => ({
      ...prevData,
      VenueRegionId: selectedRegionId, // Set the venue_regionId with selected region
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // const token = localStorage.getItem("auth")
    //   ? JSON.parse(localStorage.getItem("auth")).token
    //   : null;

    setLoading(true);
    setError(null);

    try {
      const createdVenue = await createVenue(formData); // Call the createVenue function
      console.log("Venue created successfully:", createdVenue);
      console.log(formData);
      navigate("/venues");
    } catch (error) {
      setError("Failed to create venue");
      console.error("Error creating venue:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-6 bg-white rounded-lg shadow-lg space-y-8">
      <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
        Create New Venue
      </h2>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Venue Name */}
        <div className="mb-6">
          <label
            htmlFor="VenueName"
            className="block text-lg font-semibold mb-2"
          >
            Venue Name
          </label>
          <input
            type="text"
            name="VenueName"
            id="VenueName"
            value={formData.VenueName}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        {/* Venue Address */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          <div className="mb-6">
            <label
              htmlFor="VenueAddress.city"
              className="block text-lg font-semibold mb-2"
            >
              City
            </label>
            <input
              type="text"
              name="VenueAddress.city"
              id="VenueAddress.city"
              value={formData.VenueAddress.city}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          <div className="mb-6">
            <label
              htmlFor="VenueAddress.state"
              className="block text-lg font-semibold mb-2"
            >
              State
            </label>
            <input
              type="text"
              name="VenueAddress.state"
              id="VenueAddress.state"
              value={formData.VenueAddress.state}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          <div className="mb-6">
            <label
              htmlFor="VenueAddress.country"
              className="block text-lg font-semibold mb-2"
            >
              Country
            </label>
            <input
              type="text"
              name="VenueAddress.country"
              id="VenueAddress.country"
              value={formData.VenueAddress.country}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          <div className="mb-6">
            <label
              htmlFor="VenueAddress.pincode"
              className="block text-lg font-semibold mb-2"
            >
              Pincode
            </label>
            <input
              type="text"
              name="VenueAddress.pincode"
              id="VenueAddress.pincode"
              value={formData.VenueAddress.pincode}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          <div className="mb-6">
            <label
              htmlFor="VenueAddress.landmark"
              className="block text-lg font-semibold mb-2"
            >
              Landmark
            </label>
            <input
              type="text"
              name="VenueAddress.landmark"
              id="VenueAddress.landmark"
              value={formData.VenueAddress.landmark}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        {/* Region Selection */}
        <div className="mb-6">
          <label
            htmlFor="VenueRegionId"
            className="block text-lg font-semibold mb-2"
          >
            Region
          </label>
          <select
            name="VenueRegionId"
            id="VenueRegionId"
            value={formData.VenueRegionId}
            onChange={handleRegionChange}
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          >
            <option value="">Select a Region</option>
            {regions.map((region) => (
              <option key={region.RegionId} value={region.RegionId}>
                {region.RegionName}
              </option>
            ))}
          </select>
        </div>

        {/* Map Link */}
        <div className="mb-6">
          <label htmlFor="MapLink" className="block text-lg font-semibold mb-2">
            Map Link
          </label>
          <input
            type="url"
            name="MapLink"
            id="MapLink"
            value={formData.MapLink}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Venue Description */}
        <div className="mb-6">
          <label
            htmlFor="VenueDescription"
            className="block text-lg font-semibold mb-2"
          >
            Venue Description
          </label>
          <textarea
            name="VenueDescription"
            id="VenueDescription"
            value={formData.VenueDescription}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {/* Latitude Input */}
          <div className="mb-6">
            <label
              htmlFor="Latitude"
              className="block text-lg font-semibold mb-2"
            >
              Latitude
            </label>
            <input
              type="number"
              name="Latitude"
              id="Latitude"
              value={formData.Latitude}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              step="any" // Allows decimal values
              required
            />
          </div>
          {/* Longitude Input */}
          <div className="mb-6">
            <label
              htmlFor="Longitude"
              className="block text-lg font-semibold mb-2"
            >
              Longitude
            </label>
            <input
              type="number"
              name="Longitude"
              id="Longitude"
              value={formData.Longitude}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              step="any" // Allows decimal values
              required
            />
          </div>
        </div>

        {/* Submit Button */}
        <div className="mb-6">
          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-3 rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            disabled={loading}
          >
            {loading ? "Creating Venue..." : "Create Venue"}
          </button>
        </div>
      </form>

      {error && <p className="text-red-500 text-center">{error}</p>}
    </div>
  );
};

export default CreateVenue;
