import React, { useState, useEffect } from "react";
import { createReward, fetchSponsors, fetchMilestones } from "../../api/api"; // Replace with actual API functions
import ToastNotification from "../../components/ToastNotification";

const CreateRewards = () => {
  const [formData, setFormData] = useState({
    RewardType: "",
    RewardName: "",
    RewardDescription: "",
    RewardSponsorId: "",
    MilestoneId: "",
    CouponCode: "",
  });

  const [sponsors, setSponsors] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [toast, setToast] = useState(null); // For toast notifications
  const [loading, setLoading] = useState(false);

  // Fetch sponsors and milestones on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const sponsorData = await fetchSponsors();
        setSponsors(sponsorData);
      } catch (error) {
        handleError(error, "Failed to fetch sponsors.");
      }

      try {
        const milestoneData = await fetchMilestones();
        setMilestones(milestoneData);
      } catch (error) {
        handleError(error, "Failed to fetch milestones.");
      }
    };

    fetchData();
  }, []);

  const handleError = (error, defaultMessage) => {
    const status = error?.response?.status;
    if (status === 404) {
      setToast({ message: "Data not found.", type: "error" });
    } else {
      setToast({ message: defaultMessage || "Something went wrong.", type: "error" });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await createReward(formData); // Submit form data to API
      setToast({ message: "Reward created successfully!", type: "success" });
      setFormData({
        RewardType: "",
        RewardName: "",
        RewardDescription: "",
        RewardSponsorId: "",
        MilestoneId: "",
        CouponCode: "",
      });
    } catch (error) {
      handleError(error, "Failed to create reward.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
        Create Reward
      </h2>

      {toast && (
        <ToastNotification
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Reward Type */}
        <div>
          <label htmlFor="RewardType" className="block text-lg font-semibold mb-2">
            Reward Type
          </label>
          <input
            type="text"
            name="RewardType"
            id="RewardType"
            value={formData.RewardType}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            placeholder="Enter reward type (e.g., Discount, Cashback)"
            required
          />
        </div>

        {/* Reward Name */}
        <div>
          <label htmlFor="RewardName" className="block text-lg font-semibold mb-2">
            Reward Name
          </label>
          <input
            type="text"
            name="RewardName"
            id="RewardName"
            value={formData.RewardName}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            placeholder="Enter reward name"
            required
          />
        </div>

        {/* Reward Description */}
        <div>
          <label htmlFor="RewardDescription" className="block text-lg font-semibold mb-2">
            Reward Description
          </label>
          <textarea
            name="RewardDescription"
            id="RewardDescription"
            value={formData.RewardDescription}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            placeholder="Enter reward description"
            required
          ></textarea>
        </div>

        {/* Sponsor Dropdown */}
        <div>
          <label htmlFor="RewardSponsorId" className="block text-lg font-semibold mb-2">
            Reward Sponsor
          </label>
          <select
            name="RewardSponsorId"
            id="RewardSponsorId"
            value={formData.RewardSponsorId}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            required
          >
            <option value="">Select a Sponsor</option>
            {sponsors.map((sponsor) => (
              <option key={sponsor.RewardSponsorId} value={sponsor.RewardSponsorId}>
                {sponsor.RewardSponsorName}
              </option>
            ))}
          </select>
        </div>

        {/* Milestone Dropdown */}
        <div>
          <label htmlFor="MilestoneId" className="block text-lg font-semibold mb-2">
            Milestone
          </label>
          <select
            name="MilestoneId"
            id="MilestoneId"
            value={formData.MilestoneId}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            required
          >
            <option value="">Select a Milestone</option>
            {milestones.map((milestone) => (
              <option key={milestone.MilestoneId} value={milestone.MilestoneId}>
                {milestone.MilestoneName}
              </option>
            ))}
          </select>
        </div>

        {/* Coupon Code */}
        <div>
          <label htmlFor="CouponCode" className="block text-lg font-semibold mb-2">
            Coupon Code
          </label>
          <input
            type="text"
            name="CouponCode"
            id="CouponCode"
            value={formData.CouponCode}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            placeholder="Enter coupon code"
            required
          />
        </div>

        {/* Submit Button */}
        <div>
          <button
            type="submit"
            className={`w-full px-4 py-3 text-white rounded ${
              loading ? "bg-gray-500" : "bg-blue-500 hover:bg-blue-600"
            }`}
            disabled={loading}
          >
            {loading ? "Creating Reward..." : "Create Reward"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateRewards;
