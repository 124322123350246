import React, { createContext, useState, useContext, useEffect } from "react";

// Create a context for authentication
export const AuthContext = createContext();

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(null);
  const [UserId, setUserId] = useState(null)
  const [auth, setAuth] = useState(() => {
    
    const storedAuth = localStorage.getItem("auth");
    if (storedAuth) {
      const parsedAuth = JSON.parse(storedAuth);

      if (new Date(parsedAuth.expiry) > new Date()) {
        return parsedAuth; 
      } else {
        localStorage.removeItem("auth"); // Remove expired auth data
      }
    }
    return null; // No valid auth data
  });

  useEffect(() => {
    // Sync state with localStorage on auth change
    if (auth) {
      localStorage.setItem("auth", JSON.stringify(auth));
      setUserId(auth.UserId || null); // Set UserId from auth
    } else {
      localStorage.removeItem("auth");
    }
  }, [auth]);

  const login = (data, rememberMeDuration = 24) => {
    const { token, UserId } = data;

    // Calculate expiry time
    const expiry = new Date(new Date().getTime() + rememberMeDuration * 60 * 60 * 1000);

    const authData = { token, UserId, expiry: expiry.toISOString() };

    localStorage.setItem("auth", JSON.stringify(authData)); // Save to localStorage
    setAuth(authData); // Update state
  };

  const logout = () => {
    localStorage.removeItem("auth"); // Clear localStorage
    setAuth(null); // Clear state
  };

  return (
    <AuthContext.Provider value={{ auth, UserId, login, logout, accessToken, setAccessToken }}>
      {children}
    </AuthContext.Provider>
  );
};
