import React, { useState, useEffect } from "react";
import {
  fetchVenues,
  fetchSponsors,
  fetchSports,
  uploadEventImage,
  downloadFile,
  updateEvent,
} from "../api/api";
import CropperEventImage from "../components/CropperEventImage";
import ToastNotification from "../components/ToastNotification";
import Loader from "../components/Loader";
import { FaTimes } from "react-icons/fa";
import imageCompression from "browser-image-compression";
import ClipLoader from "react-spinners/ClipLoader";

const EditEventForm = ({ event, onCancel }) => {

  const convertToIST = (date) => {
    // Convert to Date object if it's not already one
    const validDate = date instanceof Date ? date : new Date(date);
    if (isNaN(validDate)) {
      throw new Error("Invalid date provided to convertToIST");
    }
    const ISTOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
    const dateInIST = new Date(validDate.getTime() + ISTOffset);
    return dateInIST.toISOString();
  };

  const [formData, setFormData] = useState({
    EventTitle: event?.EventTitle || "",
    EventDescription: event?.EventDescription || "",
    EventType: event?.EventType || "League",
    EventLevel: event?.EventLevel || ["Novice"],
    EventImageFileId: event?.EventImages?.map((img) => img.FileId) || [],
    EventImages:
      event?.EventImages?.map((img) => ({
        FileId: img.FileId,
        Tags: img.Tags || [],
      })) || [],
    EventTags: event?.EventTags || [],
    SportId: event?.sport.SportId || "",
    TeamSize: event?.TeamSize || 0,
    IsPartnerAllowed: event?.IsPartnerAllowed || true,
    TotalTeams: event?.TotalTeams || 0,
    PriceCurrency: event?.PriceCurrency || "INR",
    Price: event?.Price || 0,
    PriceCredits: event?.PriceCredits || 0,
    isPaid: event?.isPaid || true,
    FAQ: event?.FAQ || [{ question: "", answer: "" }],
    Venue: event?.venue || {
      VenueId: event?.venue?.VenueId || "",
      VenueName: event?.venue?.VenueName || "",
      VenueAddress: {
        country: event?.venue?.VenueAddress?.country || "",
        state: event?.venue?.VenueAddress?.state || "",
        city: event?.venue?.VenueAddress?.city || "",
        pincode: event?.venue?.VenueAddress?.pincode || "",
        landmark: event?.venue?.VenueAddress?.landmark || "",
      },
      VenueRegionId: event?.venue?.RegionId || "",
      MapLink: event?.venue?.MapLink || "",
      VenueDescription: event?.venue?.VenueDescription || "",
      Longitude: event?.venue?.Longitude ?? 0,
      Latitude: event?.venue?.Latitude ?? 0,
    },
    EventSchedule: event?.event_schedule || {
      StartDate:
        event?.event_schedule?.StartDate?.slice(0, 10) ||
        new Date().toISOString(),
      StartTimeStamp:
        event?.event_schedule?.StartTimeStamp?.slice(11, 16) ||
        new Date().toISOString(),
      EndDate:
        event?.event_schedule?.EndDate?.slice(0, 10) ||
        new Date().toISOString(),
      EndTimeStamp:
        event?.event_schedule?.EndTimeStamp?.slice(11, 16) ||
        new Date().toISOString(),
      RegistrationStartDate:
        event?.event_schedule?.RegistrationStartDate?.slice(0, 10) ||
        new Date().toISOString(),
      RegistrationStartTimeStamp:
        event?.event_schedule?.RegistrationStartTimeStamp?.slice(11, 16) ||
        new Date().toISOString(),
      RegistrationEndDate:
        event?.event_schedule?.RegistrationEndDate?.slice(0, 10) ||
        new Date().toISOString(),
      RegistrationEndTimeStamp:
        event?.event_schedule?.RegistrationEndTimeStamp?.slice(11, 16) ||
        new Date().toISOString(),
      IsRecurring: event?.event_schedule?.IsRecurring || false,
      RecurrenceRule: event?.event_schedule?.RecurrenceRule || "",
      RecurrenceInterval: event?.event_schedule?.RecurrenceInterval || 0,
      RecurrenceEndDate: event?.event_schedule?.RecurrenceEndDate
        ? event.event_schedule.RecurrenceEndDate.slice(0, 10) // Extract YYYY-MM-DD
        : new Date().toISOString().slice(0, 10), // Default to today's date
      RecurrenceEndTime: event?.event_schedule?.RecurrenceEndDate
        ? new Date(event.event_schedule.RecurrenceEndDate)
          .toLocaleTimeString("en-US", { hour12: false })
          .slice(0, 5) // Extract HH:MM
        : "00:00", // Default to midnight
      RecurrenceDaysOfWeek: event?.event_schedule?.RecurrenceDaysOfWeek || [],
    },
    SponsorIds: event?.sponsors?.map((sponsor) => sponsor.SponsorId) || [],
    isActive: true,
  });

  const [venues, setVenues] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [sports, setSports] = useState([]);
  const [showCropper, setShowCropper] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [toast, setToast] = useState(null);
  const [loading, setLoading] = useState(true);
  const [usedCropOptions, setUsedCropOptions] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    if (formData.EventImages) {
      const initialUsedTags = formData.EventImages.flatMap(
        (image) => image.Tags
      );
      setUsedCropOptions(initialUsedTags);
    }
  }, [formData.EventImages]);
  // useEffect(() => {
  //   console.log("Initial formData:", formData);
  // }, [formData]);
  // Fetch Venues, Sponsors, and Sports
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedVenues = await fetchVenues();
        const fetchedSponsors = await fetchSponsors();
        const fetchedSports = await fetchSports();

        setVenues(fetchedVenues);
        setSponsors(fetchedSponsors);
        setSports(fetchedSports);
        // Set VenueRegionId if it's missing in the event details
        if (!formData.Venue.VenueRegionId && formData.Venue.VenueName) {
          const matchedVenue = fetchedVenues.find(
            (venue) => venue.VenueId === formData.Venue.VenueId
          );

          if (matchedVenue) {
            setFormData((prevData) => ({
              ...prevData,
              Venue: {
                ...prevData.Venue,
                VenueRegionId: matchedVenue.VenueRegionId,
                VenueId: matchedVenue.VenueId,
              },
            }));
          }
        }
      } catch (error) {
        console.error("Error fetching venues:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchImagePreviews = async () => {
      if (formData.EventImages.length > 0) {
        const updatedEventImages = await Promise.all(
          formData.EventImages.map(async (img) => {
            try {
              const imageUrl = await downloadFile(img.FileId);
              return { ...img, preview: imageUrl };
            } catch (error) {
              console.error(`Error fetching preview for ${img.FileId}:`, error);
              return { ...img, preview: "" };
            }
          })
        );

        setFormData((prevData) => ({
          ...prevData,
          EventImages: updatedEventImages,
        }));
      }
    };

    fetchImagePreviews();
  }, [event]);

  // Handle Input Changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Handle sponsor change
  const handleSponsorChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      SponsorIds: checked
        ? [...prevData.SponsorIds, value]
        : prevData.SponsorIds.filter((id) => id !== value),
    }));
  };

  const handleVenueChange = (e) => {
    const venueId = e.target.value;
    const selectedVenue = venues.find((venue) => venue.VenueId === venueId);

    if (selectedVenue) {
      setFormData((prevData) => ({
        ...prevData,
        Venue: {
          VenueId: selectedVenue.VenueId,
          VenueName: selectedVenue.VenueName,
          VenueAddress: selectedVenue.VenueAddress,
          VenueRegionId: selectedVenue.VenueRegionId || "",
          MapLink: selectedVenue.MapLink || "",
          VenueDescription: selectedVenue.VenueDescription || "",
          Latitude: selectedVenue.Latitude ?? 0,
          Longitude: selectedVenue.Longitude ?? 0,
        },
      }));
    }
  };

  const handleFAQChange = (index, e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedFAQs = [...prevData.FAQ];
      updatedFAQs[index] = { ...updatedFAQs[index], [name]: value };
      return { ...prevData, FAQ: updatedFAQs };
    });
  };

  const addFAQ = () => {
    setFormData((prevData) => ({
      ...prevData,
      FAQ: [...prevData.FAQ, { question: "", answer: "" }],
    }));
  };

  const handleImageCrop = async (blob, tag) => {

    const maxSize = 1 * 1024 * 1024; // 1.5MB in bytes
    // If the image size exceeds the limit, compress it
    let imageBlob = blob;
    if (blob.size > maxSize) {
      try {
        const options = {
          maxSizeMB: 1, // Maximum size in MB
          useWebWorker: true, // Enable web worker for better performance
        };
        setToast({
          message: "Compressing image...",
          type: "info",
        });
        imageBlob = await imageCompression(blob, options);
        // Check if the compression was successful
        if (imageBlob.size > maxSize) {
          setToast({
            message: "Image compression failed to reduce size under 1MB!",
            type: "error",
          });
          return;
        }
        setToast({
          message: "Image compressed successfully!",
          type: "success",
        });
      } catch (error) {
        console.error("Error compressing image:", error);
        setToast({
          message: "Image compression failed!",
          type: "error",
        });
        return;
      }
    }


    const formDataUpload = new FormData();
    formDataUpload.append("data", imageBlob);
    formDataUpload.append("alt_text", tag);
    setImageLoading(true);

    try {
      const response = await uploadEventImage(formDataUpload);
      if (response && response.FileId) {
        const imageUrl = await fetchImageUrl(response.FileId);
        if (imageUrl) {
          setFormData((prevData) => ({
            ...prevData,
            EventImageFileId: [...prevData.EventImageFileId, response.FileId],
            EventImages: [
              ...prevData.EventImages,
              { FileId: response.FileId, Tags: [tag] },
            ],
          }));

          setUsedCropOptions((prevOptions) =>
            prevOptions.includes(tag) ? prevOptions : [...prevOptions, tag]
          );

          setToast({
            message: "Image uploaded successfully!",
            type: "success",
          });
        }
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      setToast({ message: "Image upload failed!", type: "error" });
    } finally {
      setShowCropper(false);
      setImageLoading(false)
    }
  };

  const fetchImageUrl = async (fileId) => {
    try {
      const imageUrl = await downloadFile(fileId); // Assuming downloadFile returns a URL or Blob
      return imageUrl;
    } catch (error) {
      console.error("Error fetching image URL:", error);
      return null;
    }
  };

  const isAllCropOptionsUsed = () => {
    const allCropOptions = ["SPOTLIGHT", "TALL", "WIDE", "SQUARE"];
    return allCropOptions.every((option) => usedCropOptions.includes(option));
  };

  // Handle removing an image
  const handleRemoveImage = (fileId) => {
    setFormData((prevData) => ({
      ...prevData,
      EventImages: prevData.EventImages.filter(
        (image) => image.FileId !== fileId
      ),
      EventImageFileId: prevData.EventImageFileId.filter((id) => id !== fileId),
    }));
    setToast({ message: "Image removed successfully!", type: "success" });
  };

  // Function to handle image preview
  const handlePreviewImage = (imageUrl) => {
    setPreviewImage(imageUrl);
  };

  // Function to close the preview
  const closePreview = () => {
    setPreviewImage(null);
  };

  const handleDateTimeChange = (e, dateTimeField) => {
    const { value } = e.target; // e.g., "2024-11-30"



    setFormData((prevData) => ({
      ...prevData,
      EventSchedule: {
        ...prevData.EventSchedule,

        [dateTimeField]: value, // Update time field in UTC
      },
    }));
  };

  //event date handler
  const handleEventDateChange = (e, dateField, timeField) => {
    const { value } = e.target; // New date value, e.g., "2025-01-24"

    // Extract the time part from the corresponding timestamp field, default to "00:00"
    const currentTime = formData.EventSchedule[timeField]
      ? new Date(formData.EventSchedule[timeField]).toISOString().slice(11, 16)
      : "00:00";

    // Combine the new date with the existing time
    const [hours, minutes] = currentTime.split(":");
    const [year, month, day] = value.split("-").map(Number);

    const newDateTime = new Date(Date.UTC(year, month - 1, day, hours, minutes));

    setFormData((prevData) => ({
      ...prevData,
      EventSchedule: {
        ...prevData.EventSchedule,
        [dateField]: value, // Update only the date field
        [timeField]: newDateTime.toISOString(), // Synchronize the timestamp with the new date
      },
    }));
  };



  const handleEventTimeChange = (e, timeField, dateField) => {
    const { value } = e.target; // e.g., "15:00"
    const date = formData.EventSchedule[dateField]?.slice(0, 10) || ""; // Retain the previous date if available

    setFormData((prevData) => ({
      ...prevData,
      EventSchedule: {
        ...prevData.EventSchedule,
        [timeField]: `${date}T${value}:00+05:30`, // Combine date with new time in IST
      },
    }));
  };


  // Handle Registration Date Change
  const handleRegistrationDateChange = (e, dateField, timeField) => {
    const { value } = e.target; // New date value, e.g., "2025-01-24"

    // Extract the time part from the corresponding timestamp field, default to "00:00"
    const currentTime = formData.EventSchedule[timeField]
      ? new Date(formData.EventSchedule[timeField]).toISOString().slice(11, 16)
      : "00:00";

    // Combine the new date with the existing time
    const [hours, minutes] = currentTime.split(":");
    const [year, month, day] = value.split("-").map(Number);

    const newDateTime = new Date(Date.UTC(year, month - 1, day, hours, minutes));

    setFormData((prevData) => ({
      ...prevData,
      EventSchedule: {
        ...prevData.EventSchedule,
        [dateField]: value, // Update only the date field
        [timeField]: newDateTime.toISOString(), // Synchronize the timestamp with the new date
      },
    }));
  };



  // Handle Registration Start Time Change
  const handleRegistrationTimeChange = (e, timeField, dateField) => {
    const { value } = e.target; // e.g., "15:00"
    const date = formData.EventSchedule[dateField]?.slice(0, 10) || ""; // Retain the previous date if available

    setFormData((prevData) => ({
      ...prevData,
      EventSchedule: {
        ...prevData.EventSchedule,
        [timeField]: `${date}T${value}:00+05:30`, // Combine date with new time in IST
      },
    }));
  };


  const formatDateTimeForInput = (isoString) => {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };



  // Convert IST to UTC for API
  const istToUtc = (dateString, timeString = "00:00") => {
    try {
      console.log("Input dateString:", dateString); // Debugging
      console.log("Input timeString:", timeString); // Debugging

      if (!dateString || !/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
        throw new Error(
          "Invalid or missing date input. Expected format: YYYY-MM-DD."
        );
      }

      if (!timeString || !/^\d{2}:\d{2}$/.test(timeString)) {
        throw new Error(
          "Invalid or missing time input. Expected format: HH:MM."
        );
      }

      const [year, month, day] = dateString.split("-").map(Number);
      const [hour, minute] = timeString.split(":").map(Number);

      const date = new Date(Date.UTC(year, month - 1, day, hour, minute));
      if (isNaN(date.getTime())) {
        throw new Error("Constructed date is invalid.");
      }

      return date.toISOString();
    } catch (error) {
      console.error("Error in istToUtc:", error.message);
      throw error;
    }
  };


  // const extractDate = (isoString) => {
  //   if (!isoString) return ""; // Return empty string if undefined
  //   return isoString.split("T")[0]; // YYYY-MM-DD
  // };

  // const extractTime = (isoString) => {
  //   if (!isoString) return ""; // Return empty string if undefined
  //   const timePart = isoString.split("T")[1]; // HH:MM:SSZ
  //   return timePart ? timePart.slice(0, 5) : ""; // HH:MM or empty if invalid
  // };

  // Toggle IsRecurring
  const handleIsRecurringChange = (e) => {
    const isRecurring = e.target.checked;

    setFormData((prevData) => ({
      ...prevData,
      EventSchedule: {
        ...prevData.EventSchedule,
        IsRecurring: isRecurring,
        ...(isRecurring
          ? {} // If IsRecurring is true, retain current values
          : {
            RecurrenceRule: prevData.EventSchedule.RecurrenceRule || "",
            RecurrenceInterval:
              prevData.EventSchedule.RecurrenceInterval || 0,
            RecurrenceEndDate: prevData.EventSchedule.RecurrenceEndDate || "",
            RecurrenceDaysOfWeek:
              prevData.EventSchedule.RecurrenceDaysOfWeek || [],
          }), // Retain the previous recurrence-related fields if available
      },
    }));
  };

  // Handle Recurrence Rule Change
  const handleRecurrenceRuleChange = (e) => {
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      EventSchedule: {
        ...prev.EventSchedule,
        RecurrenceRule: value, // Update the rule in state
      },
    }));
  };


  // Handle Recurrence Interval Change
  const handleRecurrenceIntervalChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      EventSchedule: {
        ...prev.EventSchedule,
        RecurrenceInterval: Number(e.target.value),
      },
    }));
  };

  // Handle Recurrence End Date Change
  const handleRecurrenceEndDateChange = (e) => {
    const dateValue = e.target.value;
    const currentTime = formData.EventSchedule.RecurrenceEndTime || "00:00";

    setFormData((prevData) => ({
      ...prevData,
      EventSchedule: {
        ...prevData.EventSchedule,
        RecurrenceEndDate: dateValue,
        RecurrenceEndTime: currentTime, // Retain current time
      },
    }));
  };

  // Handle Recurrence End Time Change
  const handleRecurrenceEndTimeChange = (e) => {
    const timeValue = e.target.value; // e.g., "15:30"
    const currentDate = formData.EventSchedule.RecurrenceEndDate || ""; // Use existing date or fallback

    setFormData((prevData) => ({
      ...prevData,
      EventSchedule: {
        ...prevData.EventSchedule,
        RecurrenceEndTime: timeValue, // Store as HH:MM
      },
    }));
  };

  // Handle Days of the Week Checkbox Change
  const handleDaysOfWeekChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      EventSchedule: {
        ...prev.EventSchedule,
        RecurrenceDaysOfWeek: checked
          ? [...prev.EventSchedule.RecurrenceDaysOfWeek, value]
          : prev.EventSchedule.RecurrenceDaysOfWeek.filter((d) => d !== value),
      },
    }));
  };

  // Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const { RecurrenceEndDate, RecurrenceEndTime } = formData.EventSchedule;

    // Validate and clean inputs
    const validRecurrenceEndDate =
      RecurrenceEndDate?.slice(0, 10) || new Date().toISOString().slice(0, 10);
    const validRecurrenceEndTime = RecurrenceEndTime || "00:00";

    console.log("RecurrenceEndDate before istToUtc:", validRecurrenceEndDate);
    console.log("RecurrenceEndTime before istToUtc:", validRecurrenceEndTime);

    try {
      const payload = {
        ...formData,
        EventSchedule: {
          ...formData.EventSchedule,
          StartDate: new Date(formData.EventSchedule.StartTimeStamp).toISOString(),
          EndDate: new Date(formData.EventSchedule.EndTimeStamp).toISOString(),
          StartTimeStamp: new Date(formData.EventSchedule.StartTimeStamp).toISOString(),
          EndTimeStamp: new Date(formData.EventSchedule.EndTimeStamp).toISOString(),
          RegistrationStartDate: new Date(formData.EventSchedule.RegistrationStartTimeStamp).toISOString(),
          RegistrationStartTimeStamp: new Date(formData.EventSchedule.RegistrationStartTimeStamp).toISOString(),
          RegistrationEndDate: new Date(formData.EventSchedule.RegistrationEndTimeStamp).toISOString(),
          RegistrationEndTimeStamp: new Date(formData.EventSchedule.RegistrationEndTimeStamp).toISOString(),
          RecurrenceEndDate: new Date(formData.EventSchedule.RecurrenceEndDate).toISOString(),
        },
      };


      console.log("Payload:", payload);

      await updateEvent(event.EventId, payload);
      setToast({
        message: "Event updated successfully!",
        type: "success",
      });

      setTimeout(() => {
        onCancel();
        window.location.reload();
      }, 500);
    } catch (error) {
      console.log(
        "Constructed RecurrenceEndDate for API:",
        RecurrenceEndDate
          ? istToUtc(RecurrenceEndDate, RecurrenceEndTime || "00:00")
          : null
      );

      console.error("Error updating event:", error.message);
      setToast({
        message: "Failed to update the event. Please try again.",
        type: "error",
      });
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative bg-white p-4 md:p-6 rounded-lg shadow-lg w-11/12 max-w-2xl">
        {/* Close Button */}
        <button
          type="button"
          onClick={onCancel}
          className="absolute top-3 right-3 text-gray-600 hover:text-red-500 text-2xl"
        >
          <FaTimes />
        </button>

        <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-center">
          Edit Event
        </h2>
        {toast && (
          <ToastNotification
            message={toast.message}
            type={toast.type}
            onClose={() => setToast(null)}
          />
        )}

        <div className="overflow-y-auto max-h-[70vh] pr-2">
          <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
            {/* Event Title */}
            <div>
              <label className="block text-lg font-semibold mb-1">
                Event Title *
              </label>
              <input
                type="text"
                name="EventTitle"
                value={formData.EventTitle}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-lg"
                required
              />
            </div>

            {/* Event Description */}
            <div>
              <label className="block text-lg font-semibold mb-1">
                Event Description *
              </label>
              <textarea
                name="EventDescription"
                value={formData.EventDescription}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-lg"
                required
              />
            </div>

            {/* Event Images */}
            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2">
                Event Images
              </label>

              {/* Display Uploaded Images */}
              {formData.EventImages.length > 0 ? (
                formData.EventImages.map((image, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between mt-2 bg-gray-100 p-2 rounded-lg"
                  >
                    <span className="text-gray-700">
                      Image {index + 1} ({image.Tags.join(", ")})
                    </span>
                    <div className="flex space-x-2">
                      {/* Preview Button */}
                      <button
                        type="button"
                        onClick={() => handlePreviewImage(image.preview)}
                        className="text-blue-500 hover:underline"
                      >
                        Preview
                      </button>

                      {/* Remove Button */}
                      <button
                        type="button"
                        onClick={() => {
                          setFormData((prevData) => ({
                            ...prevData,
                            EventImages: prevData.EventImages.filter(
                              (_, i) => i !== index
                            ),
                            EventImageFileId: prevData.EventImageFileId.filter(
                              (id) => id !== image.FileId
                            ),
                          }));
                          setUsedCropOptions((prevOptions) =>
                            prevOptions.filter(
                              (option) => option !== image.Tags[0]
                            )
                          );
                        }}
                        className="text-red-500 hover:underline"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-gray-500">No images uploaded.</p>
              )}

              {/* Add New Image Button */}
              <button
                type="button"
                onClick={() => setShowCropper(true)}
                disabled={isAllCropOptionsUsed()}
                className={`bg-blue-500 text-white px-4 py-2 rounded mt-4 ${isAllCropOptionsUsed()
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-blue-600"
                  }`}
              >
                {imageLoading ? (<>
                  <div className="w-30 gap-x-1 flex items-center">
                    <p>Uploading</p> <ClipLoader color={"white"} loading={true} size={20} />
                  </div>
                </>) : ('Add New Image')}

              </button>

              {/* Image Preview Overlay */}
              {previewImage && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                  <div className="relative bg-white p-4 rounded-lg shadow-lg max-w-lg w-full">
                    <button
                      type="button"
                      onClick={() => setPreviewImage(null)}
                      className="absolute top-2 right-2 text-gray-700 hover:text-gray-900 text-2xl"
                    >
                      ✕
                    </button>
                    <img
                      src={previewImage}
                      alt="Preview"
                      className="w-full h-auto max-h-[80vh] object-contain"
                    />
                  </div>
                </div>
              )}

              {/* Cropper Component */}
              {showCropper && (
                <CropperEventImage
                  onCropComplete={handleImageCrop}
                  onClose={() => setShowCropper(false)}
                  usedCropOptions={usedCropOptions}
                />
              )}
            </div>

            {/* Event Type */}
            <div>
              <label className="block text-lg font-semibold mb-1">
                Event Type
              </label>
              <select
                name="EventType"
                value={formData.EventType}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-lg"
              >
                <option value="League">League</option>
                <option value="Tournament">Tournament</option>
                <option value="Bootcamp">Bootcamp</option>
              </select>
            </div>

            {/* Sport */}
            <div className="mb-4">
              <label className="block text-lg font-semibold mb-2">Sport</label>
              <select
                name="SportId"
                value={formData.SportId || ""}
                onChange={handleInputChange}
                className="w-full p-3 border rounded"
                required
              >
                <option value="">Select Sport</option>
                {sports.map((sport) => (
                  <option key={sport.SportId} value={sport.SportId}>
                    {sport.SportType}
                  </option>
                ))}
              </select>
            </div>

            {/* Team Size */}
            <div>
              <label className="block text-lg font-semibold mb-1">
                Team Size
              </label>
              <input
                type="text"
                name="TeamSize"
                value={formData.TeamSize}
                onChange={handleInputChange}
                min="1"
                className="w-full p-2 border border-gray-300 rounded-lg"
              />
            </div>

            {/* Team Limit */}
            <div>
              <label className="block text-lg font-semibold mb-1">
                Total Teams
              </label>
              <input
                type="text"
                name="Total Teams"
                value={formData.TotalTeams}
                onChange={handleInputChange}
                min="1"
                className="w-full p-2 border border-gray-300 rounded-lg"
              />
            </div>

            {/* Price */}
            <div className="mb-4">
              <label className="block text-lg font-semibold mb-2">
                Price (INR)
              </label>
              <input
                type="number"
                name="Price"
                value={formData.Price || "0"}
                onChange={handleInputChange}
                className="w-full p-3 border rounded"
                min="0"
              />
            </div>

            {/* Price Credits */}
            <div className="mb-4">
              <label className="block text-lg font-semibold mb-2">
                Price Credits
              </label>
              <input
                type="number"
                name="PriceCredits"
                value={formData.PriceCredits || "0"}
                onChange={handleInputChange}
                className="w-full p-3 border rounded"
                min="0"
              />
            </div>

            {/* Venue */}
            <div>
              <label className="block text-lg font-semibold mb-1">
                Venue *
              </label>
              <select
                name="VenueId"
                value={formData.Venue.VenueId}
                onChange={handleVenueChange}
                className="w-full p-2 border border-gray-300 rounded-lg"
                required
              >
                <option value="">Select a Venue</option>
                {venues.map((venue) => (
                  <option key={venue.VenueId} value={venue.VenueId}>
                    {venue.VenueName}
                  </option>
                ))}
              </select>
            </div>

            {/* Sponsors */}
            <div className="mb-4">
              <label className="block text-lg font-semibold mb-2">
                Sponsors
              </label>
              <div className="flex flex-wrap gap-4">
                {sponsors.map((sponsor) => (
                  <div
                    key={sponsor.SponsorId}
                    className="flex items-center gap-2"
                  >
                    <input
                      type="checkbox"
                      id={`sponsor-${sponsor.SponsorId}`}
                      name="SponsorIds"
                      value={sponsor.SponsorId}
                      checked={formData.SponsorIds.includes(sponsor.SponsorId)} // Check against formData
                      onChange={handleSponsorChange}
                      className="h-5 w-5"
                    />
                    <label
                      htmlFor={`sponsor-${sponsor.SponsorId}`}
                      className="text-gray-700"
                    >
                      {sponsor.SponsorName}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            {/* Event Start Date & Time */}
            <div className="mb-6">
              {console.log("event start date and time: ", formData.EventSchedule.StartTimeStamp)}
              <label className="block text-lg font-semibold mb-2">
                Event Start Date & Time *
              </label>
              <div className="flex gap-4">
                <input
                  type="datetime-local"
                  name="StartTimeStamp"
                  value={
                    (formatDateTimeForInput(formData.EventSchedule.StartTimeStamp))

                  }
                  min={convertToIST(new Date())}
                  onChange={(e) =>
                    handleDateTimeChange(e, "StartTimeStamp")
                  }
                  className="w-1/2 p-3 border border-gray-300 rounded-lg"
                  required
                />

              </div>
            </div>

            {/* Event End Date & Time */}
            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2">
                Event End Date & Time *
              </label>
              <div className="flex gap-4">
                {/* End Date Input */}
                <input
                  type="datetime-local"
                  name="EndTimeStamp"
                  value={formatDateTimeForInput(formData.EventSchedule.EndTimeStamp)}
                  min={convertToIST(new Date())}
                  onChange={(e) =>
                    handleDateTimeChange(e, "EndTimeStamp")
                  }
                  className="w-1/2 p-3 border border-gray-300 rounded-lg"
                  required
                />

              </div>
            </div>

            {/* Registration Start Date & Time */}
            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2">
                Registration Start Date & Time *
              </label>
              <div className="flex gap-4">
                {/* Start Date Input */}
                <input
                  type="datetime-local"
                  name="RegistrationStartTimeStamp"
                  value={formatDateTimeForInput(formData.EventSchedule.RegistrationStartTimeStamp)}
                  min={convertToIST(new Date())}
                  onChange={(e) =>
                    handleDateTimeChange(
                      e,

                      "RegistrationStartTimeStamp"
                    )
                  }
                  className="w-1/2 p-3 border border-gray-300 rounded-lg"
                  required
                />

              </div>
            </div>

            {/* Registration End Date & Time */}
            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2">
                Registration End Date & Time *
              </label>
              <div className="flex gap-4">
                <input
                  type="datetime-local"
                  name="RegistrationEndTimeStamp"
                  value={formatDateTimeForInput(formData.EventSchedule.RegistrationEndTimeStamp)}
                  min={convertToIST(new Date())}
                  onChange={(e) =>
                    handleDateTimeChange(
                      e,
                      "RegistrationEndTimeStamp"
                    )
                  }
                  className="w-1/2 p-3 border border-gray-300 rounded-lg"
                  required
                />

              </div>
            </div>

            {/* Recurrence Details */}
            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2">
                Is Recurring Event?
              </label>
              <input
                type="checkbox"
                name="IsRecurring"
                checked={formData.EventSchedule.IsRecurring}
                onChange={handleIsRecurringChange} // Call the handler here
                className="mr-2"
              />
            </div>

            {/* Recurrence Rule */}
            {formData.EventSchedule.IsRecurring && (
              <>
                <div className="mb-6">
                  <label className="block text-lg font-semibold mb-2">
                    Recurrence Rule
                  </label>
                  <select
                    name="RecurrenceRule"
                    value={formData.EventSchedule.RecurrenceRule} // Bind to state
                    onChange={handleRecurrenceRuleChange} // Update state on change
                    className="w-full p-3 border border-gray-300 rounded-lg"
                  >
                    <option value="">Select Recurrence Rule</option>{" "}
                    {/* Default placeholder */}
                    <option value="DAILY">Daily</option>
                    <option value="WEEKLY">Weekly</option>
                    <option value="MONTHLY">Monthly</option>
                  </select>
                </div>

                {/* Recurrence Interval */}
                <div className="mb-6">
                  <label className="block text-lg font-semibold mb-2">
                    Recurrence Interval (Number of Days/Weeks/Months)
                  </label>
                  <input
                    type="number"
                    name="RecurrenceInterval"
                    value={formData.EventSchedule.RecurrenceInterval}
                    onChange={handleRecurrenceIntervalChange} // Call the handler here
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    min="1"
                  />
                </div>
                {/* Recurrence End Date & Time */}
                <div className="mb-6">
                  <label className="block text-lg font-semibold mb-2">
                    Recurrence End Date & Time
                  </label>
                  <div className="flex gap-4">
                    {/* Recurrence End Date Input */}
                    {/* Recurrence End Time Input */}
                    <input
                      type="datetime-local"
                      name="RecurrenceEndDate"
                      value={formatDateTimeForInput(formData.EventSchedule.RecurrenceEndDate)}
                      min={convertToIST(new Date())}
                      onChange={(e) =>
                        handleDateTimeChange(
                          e,
                          "RecurrenceEndDate"
                        )
                      }
                      className="w-1/2 p-3 border border-gray-300 rounded-lg"
                      required
                    />
                  </div>
                </div>

                {/* Recurrence Days of the Week */}
                {formData.EventSchedule.RecurrenceRule === "WEEKLY" && (
                  <div className="mb-6">
                    <label className="block text-lg font-semibold mb-2">
                      Recurrence Days of the Week
                    </label>
                    <div className="grid grid-cols-2 gap-4">
                      {[
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday",
                        "Sunday",
                      ].map((day) => (
                        <div key={day} className="flex items-center">
                          <input
                            type="checkbox"
                            id={day}
                            name="RecurrenceDaysOfWeek"
                            value={day}
                            checked={formData.EventSchedule.RecurrenceDaysOfWeek.includes(
                              day
                            )}
                            onChange={handleDaysOfWeekChange} // Call the handler here
                            className="mr-2"
                          />
                          <label htmlFor={day} className="text-gray-700">
                            {day}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}

            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2">FAQs</label>
              {formData.FAQ.map((faq, index) => (
                <div key={index} className="mb-4">
                  <input
                    type="text"
                    name="question"
                    placeholder="Question"
                    value={faq.question}
                    onChange={(e) => handleFAQChange(index, e)}
                    className="w-full p-2 border border-gray-300 rounded-lg mb-2"
                  />
                  <textarea
                    name="answer"
                    placeholder="Answer"
                    value={faq.answer}
                    onChange={(e) => handleFAQChange(index, e)}
                    className="w-full p-2 border border-gray-300 rounded-lg"
                  />
                </div>
              ))}
              <button
                type="button"
                onClick={addFAQ}
                className="bg-blue-500 text-white p-2 rounded-lg"
              >
                Add FAQ
              </button>
            </div>

            {/* Submit Button */}
            <div className="text-right">
              <button
                type="submit"
                className="bg-blue-500 text-white p-2 rounded-lg"
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditEventForm;
