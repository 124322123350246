import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // make sure you're importing Routes and Route
import { AuthProvider } from "./AuthContext"; // Auth provider
import PrivateRoute from "./components/PrivateRoute"; // PrivateRoute to handle protected routes
import DashboardHome from "../admin/pages/DashboardHome"; // Your dashboard page
import CreateEvent from "../admin/pages/CreateEvent"; // Create Event Page
import EventDetails from "../admin/pages/EventDetails"; // Event Details Page
import SponsorsList from "../admin/pages/SponsorsList"; // Sponsors Section
import VenuesList from "./pages/VenuesList"; // Venues Section
import Sidebar from "../admin/Sidebar"; // Sidebar Component
import EventsList from "../admin/pages/EventsList"; // Events List Page
import Profile from "./pages/Profile"; // Profile Page
import CreateVenue from "./pages/CreateVenue";
import CreateSponsor from "./pages/CreateSponsor";
import TeamsList from "./pages/TeamsList";
import RegistrationDetails from "./pages/RegistrationDetails"
import LoginPage from "./pages/LoginPage"; // Login Page Component
import CreateRegion from "./pages/CreateRegion";
import UserList from "./pages/UserList";
import UserDetails from "./pages/UserDetails";
import CoachingList from "./pages/CoachingList";
import CoachingDetails from "./pages/CoachingDetails";
import CreateCoaching from "./pages/CreateCoaching";
import CreateUser from "./pages/Createuser";
import CreateVenueSlot from "./pages/CreateVenueSlot";
import SlotDetails from "./pages/VenueSlotDetail";
import PlayPassList from "./pages/PlayPass/PlayPassList";
import PlaypassDetails from "./pages/PlayPass/PlaypassDetails";
import CreatePlaypass from "./pages/PlayPass/CreatePlaypass";
import CreateCoachingAllocation from "./pages/CreateCoachingAllocation";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <div className="flex flex-col md:flex-row">
          {/* Sidebar Component */}
          <Sidebar />

          <div className="flex-1 bg-gray-100 p-4 md:ml-64">
            {/* Define Routes within <Routes> */}
            <Routes>
              {/* Public Routes */}
              <Route path="/login" element={<LoginPage />} />{" "}
              {/* Login route */}
              {/* Protected Routes - wrapped with PrivateRoute to ensure authentication */}
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <DashboardHome />
                  </PrivateRoute>
                }
              />
              <Route
                path="/events"
                element={
                  <PrivateRoute>
                    <EventsList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/events/details/:id"
                element={
                  <PrivateRoute>
                    <EventDetails />
                  </PrivateRoute>
                }
              />
              <Route path="/event/details/:eventId/registration" element={<PrivateRoute><RegistrationDetails /></PrivateRoute>} />
              <Route
                path="/events/create"
                element={
                  <PrivateRoute>
                    <CreateEvent />
                  </PrivateRoute>
                }
              />

            
                {/* Play pass listing */}
              <Route path="/playpass" element={<PrivateRoute><PlayPassList /></PrivateRoute>} />

              {/* Play Pass details */}
              <Route
                path="/playpass/details/:id"
                element={
                  <PrivateRoute>
                    <PlaypassDetails />
                  </PrivateRoute>
                }
              />

              <Route path="/playpass/create" element={<PrivateRoute><CreatePlaypass /></PrivateRoute>} />


              <Route
                path="/sponsors"
                element={
                  <PrivateRoute>
                    <SponsorsList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/sponsors/create"
                element={
                  <PrivateRoute>
                    <CreateSponsor />
                  </PrivateRoute>
                }
              />
              <Route
                path="/venues"
                element={
                  <PrivateRoute>
                    <VenuesList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/venues/create"
                element={
                  <PrivateRoute>
                    <CreateVenue />
                  </PrivateRoute>
                }
              />
              <Route
                path="/venues/createSlot"
                element={
                  <PrivateRoute>
                    <CreateVenueSlot />
                  </PrivateRoute>
                }
              />
              <Route
                path="/venues/slots/:venueId"
                element={
                  <PrivateRoute>
                    <SlotDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/region/create"
                element={
                  <PrivateRoute>
                    <CreateRegion />
                  </PrivateRoute>
                }
              />
              <Route
                path="/master/teams"
                element={
                  <PrivateRoute>
                    <TeamsList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/master/user-master"
                element={
                  <PrivateRoute>
                    <UserList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/master/user-master/:userId"
                element={
                  <PrivateRoute>
                    <UserDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/user/create"
                element={
                  <PrivateRoute>
                    <CreateUser />
                  </PrivateRoute>
                }
              />
              <Route
                path="/coaching"
                element={
                  <PrivateRoute>
                    <CoachingList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/coaching/create"
                element={
                  <PrivateRoute>
                    <CreateCoaching />
                  </PrivateRoute>
                }
              />
              <Route
                path="/coaching/createSlot"
                element={
                  <PrivateRoute>
                    <CreateCoachingAllocation />
                  </PrivateRoute>
                }
              />
              <Route
                path="/coaching/details/:id"
                element={
                  <PrivateRoute>
                    <CoachingDetails />
                  </PrivateRoute>
                }
              />

              <Route
                path="/profile"
                element={
                  <PrivateRoute>
                    <Profile />
                  </PrivateRoute>
                }
              />
            </Routes>
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;
